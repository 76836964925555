import React from "react";

const ProgressBar = (props) => {
    const { percentage  } = props;

    const getColor =(percentage)=>{
        if(percentage >0 && percentage<50) {
            return ["bg-red-200", "bg-red-500"];
        }else if ( 50 <= percentage && percentage < 70){
            return ["bg-orange-200", "bg-orange-500"];

        }else if (70 <= percentage && percentage<99){
            return ["bg-teal-200", "bg-teal-500"];

        }else if ( percentage===100){
            return ["bg-green-200", "bg-green-500"];

        }else{
            throw Error(`should not get here percentage=[${percentage}]`);
        }
    };
    const [c1, c2] = getColor(percentage)
    return (
        <div className="flex items-center">
            <span className="mr-2">{`${percentage}%`}</span>
            <div className="relative w-full">
                <div className={`overflow-hidden h-2 text-xs flex rounded ${c1}`}>
                    <div
                        style={{ width: `${percentage}%` }}
                        className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${c2}`}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;