export const CreateAccount = {
    title: "Create Account",
    buttonName: "Create Account",
    data: [
        {
            sectionName: "Login Information",
            globalLayout: "12/12",
            globalInputType: {htmlType: "input", type: "text"},
            fieldsData: [
                {name: "username"},
                {name: "provider", readonly: true},
                {name: "password", type: "password"}
            ]
        }
    ]
}


export const companySettings = {
    title: "Create Company",
    buttonName: "Create Company",
    data: [
        {
            sectionName: "Company Details",
            globalLayout: "6/12",
            globalInputType: {htmlType: "input", type: "text"},
            fieldsData: [
                {name: "name"},
                {name: "website"},
                {name: "founded"}
            ]
        },
        {
            sectionName: "About",
            globalLayout: "12/12",
            globalInputType: {htmlType: "textarea", type: "text", row: 4},
            fieldsData: [
                {name: "about_company", layout: "12/12"},
            ]
        }
    ]
}

export const accountSettings = {
    title: "Edit Account",
    buttonName: "Save Changes",
    data: [
        {
            sectionName: "Login Information",
            globalLayout: "6/12",
            globalInputType: {htmlType: "input", type: "text"},
            fieldsData: [
                {name: "username"},
                {name: "provider", readonly: true},
                {name: "password", type: "password"}
            ]
        },
        {
            sectionName: "User Information",
            globalLayout: "6/12",
            globalInputType: {htmlType: "input", type: "text"},
            fieldsData: [
                {name: "email", type: "email"},
                {name: "given_name"},
                {name: "family_name"}]
        }
        // ,
        // {
        //     sectionName: "Platform Information",
        //     globalLayout: "6/12",
        //     globalInputType: {htmlType: "select"},
        //     fieldsData: [
        //         {name: "roll", values: ["", "Recruiter Manager", "Interviewer", "Candidate"]}
        //     ]
        // },
    ]
}
//

export const profileForm = {
    title: "Edit Profile",
    buttonName: "Save Changes",
    data: [
        {
            sectionName: "",
            globalLayout: "4/12",
            globalInputType: {htmlType: "dropzone", type: "file"},
            fieldsData: [
                {name: "profile_image"}
            ]
        },

        {
            sectionName: "Job Information",
            globalLayout: "12/12",
            globalInputType: {htmlType: "input", type: "text"},
            fieldsData: [
                {name: "title"},
                {name: "roll", htmlType: "select", values: ["", "Recruiter Manager", "Interviewer", "Candidate"]}


            ]
        },
        {
            sectionName: "Contact Information",
            globalLayout: "4/12",
            globalInputType: {htmlType: "input", type: "text"},
            fieldsData: [
                {name: "address", layout: "12/12"},
                {name: "city"},
                {name: "country"},
                {name: "postal_code", type: "number"}]
        },
        {
            sectionName: "Education",
            globalLayout: "4/12",
            globalInputType: {htmlType: "input", type: "text"},
            fieldsData: [
                {name: "school"},
                {name: "degree"},
                {name: "field_of_study"},
                {name: "grad", type: "number"},
                {name: "start_date", type: "date"},
                {name: "end_date", type: "date"},
            ]
        },
        {
            sectionName: "About",
            globalLayout: "12/12",
            globalInputType: {htmlType: "textarea", type: "text", row: 4},
            fieldsData: [
                {name: "about_me", layout: "12/12"},
                {name: "activities_and_societies", layout: "12/12"},
            ]
        }
    ]
}