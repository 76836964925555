import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";

import createSagaMiddleware from "redux-saga";

import authReducer from "./auth/Reducer";
import userReducer from "./user/Reducer";
import projectReducer from "./project/Reducer";

import saga from "./sagas/saga";
import reduxThunk from "redux-thunk"
import {reducer as formReducer} from "redux-form";
import companyReducer from "./reducers/companyReducer";
import profileReducer from "./reducers/profileReducer";
import authReducer1 from "./reducers/authReducer";

import history from "../history";

const reducers = combineReducers({
    auth: authReducer,
    user: userReducer,
    project: projectReducer,
    auth1: authReducer1,
    form: formReducer,
    companies:companyReducer,
    profile: profileReducer
});

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) )|| compose;

export const store = createStore(
    connectRouter(history)(reducers),
    composeEnhancers(
        applyMiddleware(
            reduxThunk,
            routerMiddleware(history), // for dispatching history actions
            sagaMiddleware
        )
    )
);

sagaMiddleware.run(saga);
