import React from "react"
import {Field} from "redux-form";


class FormField extends React.Component {

    convertCamelCaseToSentenceCase = camelCase => {
        let result = camelCase.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    };

    toProperCase = str=> {
        return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    };
    convertUnderScoreSentenceCase = underScore => {
        if (underScore) {
            let result = underScore.replace(/_/g, ' ');
            return this.toProperCase(result);
        }

    };


    renderError({error, touched}) {
        if (touched && error) {
            console.log("error", error)
            return (
                <div className="ui error message">
                    <div className="header">{error}</div>
                </div>
            );
        }
    }

    renderInputHtmlType = (input, label, fieldType, readonly) => {
        const typeToUse = (fieldType ? fieldType : this.props.globalInputType)
        return (<input
            {...input}
            autoComplete="off"
            type={typeToUse}
            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            placeholder={label}
            readOnly={readonly}
        />);

    }
    renderInput = ({input, label, meta, type, readonly}) => {
        // const className = `${classNameInput} ${meta.error && meta.touched? 'error': ''}`;
        return (

            <div className="relative w-full mb-3">
                <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                >
                    {label}
                </label>
                {this.renderInputHtmlType(input, label, type, readonly)}

                {this.renderError(meta)}
            </div>
        );

    }

    render() {
        const {globalLayout, name, layout, type, readonly} = this.props;
        const layoutToUse = (layout ? layout : globalLayout)
        return (
            <div className={`w-full lg:w-${layoutToUse} px-4`}>
                <Field name={name}
                       label={this.convertUnderScoreSentenceCase(name)}
                       component={this.renderInput}
                       type={type}
                       readonly={readonly}
                />
            </div>
        );
    }


}

export default FormField;