export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_RESET = "LOGIN_RESET";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const REGISTER_RESET = "REGISTER_RESET";

export const AUTO_LOGIN = "AUTO_LOGIN";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
