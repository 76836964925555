import React from "react"

const TextOnly = (props) => {

    const { text} = props
    return (
        <div className="flex">
            {text}
        </div>
    );

};

export default TextOnly;