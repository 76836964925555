import React, {Component} from 'react';
import annyang from "annyang";

class Welcome extends React.Component {
    render() {
        return <div>Say "hello"!</div>;
    }
}

class SaidHello extends React.Component {
    render() {
        return <div>Well, hello! How are you?</div>;
    }
}


class VoiceInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hello: false, lines: []};
    }

    componentDidMount() {
        if (!annyang) {
            console.log("Speech Recognition is not supported");
        } else {

            // var anything = function(anything) {
            //     alert(anything);
            // };

            // choose language
            annyang.setLanguage('he');
            // Let's define a command.
            var commands = {
                // 'hello': () => this.setState({hello: true})
                // 'hello': () => { alert('Hello world!');},
                '*anything': (anything) => this.setState(previousState => ({
                    lines: [...previousState.lines, anything]
                }))


            };

            // Add our commands to annyang
            annyang.addCommands(commands);

            // Start listening.
            annyang.start();

        }
    }

    render() {
        return (
            <div dir='rtl'>
                <h1 dir='rtl' className="text-gray-800 font-semibold text-6xl">נא לדבר...</h1>
                { this.state.lines.map((item, key) => {
                    return <div key={key}
                                className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100">
                        <p>{item}</p>
                    </div>
                })}
            </div>
        );
    }

}

export default VoiceInput;