import React from "react"
import {Link} from "react-router-dom";
import FeaturesList from "./FeaturesList";
import Item from "./Item";
import CardPeople from "./CardPeople";
import SubmitForm from "./SubmitForm";

const Main = ()=>{
  return(
      <main>
          <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
              <div
                  className="absolute top-0 w-full h-full bg-center bg-cover"
                  style={{
                      backgroundImage:
                          "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')",
                  }}
              >
            <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-75 bg-black"
            ></span>
              </div>
              <div className="container relative mx-auto">
                  <div className="items-center flex flex-wrap">
                      <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                          <div className="container mx-auto px-4">
                              <h1 className="text-white font-semibold text-4xl">
                                  Qualiifly
                              </h1>
                              <h1 className="text-white font-semibold text-6xl">
                                  Your growth begins with us.
                              </h1>

                              <p className="mt-4 text-lg text-gray-300">
                                  We are a startup company aimed at making your technical interview process as smooth and as short as possible by providing simple,
                                  convenient, and efficient platforms: Interview-as-a-Service (IAAS)
                              </p>
                          </div>
                          <div className="px-6 py-10">

                              <a
                                  href="#contact-us"
                                  className=" bg-gray-700 text-white active:bg-gray-700 text-sm font-bold uppercase  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"

                                  // className="font-bold text-gray-800 mt-8"
                              >
                                  Contact Us
                              </a>

                          </div>
                      </div>
                  </div>
              </div>
              <div
                  className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                  style={{ transform: "translateZ(0)" }}
              >
                  <svg
                      className="absolute bottom-0 overflow-hidden"
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="none"
                      version="1.1"
                      viewBox="0 0 2560 100"
                      x="0"
                      y="0"
                  >
                      <polygon
                          className="text-gray-300 fill-current"
                          points="2560 0 2560 100 0 100"
                      ></polygon>
                  </svg>
              </div>
          </div>
          {/*===================================================*/}
          <section className="pb-20 bg-gray-300 -mt-24">
              <div className="container mx-auto px-4">
                    <FeaturesList />

                  <div className="flex flex-wrap items-center mt-32">
                      <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                          <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                              <i className="fas fa-user-friends text-xl"></i>
                          </div>
                          <h3 className="text-3xl mb-2 font-semibold leading-normal">
                              Start Using our Services Today
                          </h3>
                          <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                              Don't waste valuable employee time with throwaway interviews.
                              Use our services- this will allow your employees to focus on their day to day jobs to achieve better productivity,
                              which should reduce the number of staff required per task
                          </p>
                      </div>

                      <div className="w-full md:w-4/12 px-4 mr-auto ml-auto py-10">
                          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blue-600">
                              <img
                                  alt="..."
                                  src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
                                  className="w-full align-middle rounded-t-lg"
                              />
                              <blockquote className="relative p-8 mb-4">
                                  <svg
                                      preserveAspectRatio="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 583 95"
                                      className="absolute left-0 w-full block h-95-px -top-94-px"
                                  >
                                      <polygon
                                          points="-30,95 583,95 583,65"
                                          className="text-blue-600 fill-current"
                                      ></polygon>
                                  </svg>
                                  <h4 className="text-xl font-bold text-white">
                                      Top Notch interviewers
                                  </h4>
                                  <p className="text-md font-light mt-2 text-white">
                                      Our <i>Interview-as-a-Service method (IAAS)</i> provides your company with access to a comprehensive pool of top talent interviewers
                                      from <i>FAMMONAL</i> (Facebook / Aws / Microsoft / Mobileye / Oracle / Netflix / Apple / Linkedin) companies and more.
                                  </p>
                              </blockquote>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="relative py-20">
              <div
                  className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                  style={{ transform: "translateZ(0)" }}
              >
                  <svg
                      className="absolute bottom-0 overflow-hidden"
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="none"
                      version="1.1"
                      viewBox="0 0 2560 100"
                      x="0"
                      y="0"
                  >
                      <polygon
                          className="text-white fill-current"
                          points="2560 0 2560 100 0 100"
                      ></polygon>
                  </svg>
              </div>

              <div className="container mx-auto px-4">
                  <div className="items-center flex flex-wrap">
                      <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                          <img
                              alt="..."
                              className="max-w-full rounded-lg shadow-lg"
                              src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                          />
                      </div>
                      <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                          <div className="md:pr-12 py-6">
                              <div className="text-blue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-blue-300">
                                  <i className="fas fa-rocket text-xl"></i>
                              </div>
                              <h3 className="text-3xl font-semibold">Our Unique Value Proposition</h3>
                              <p className="mt-4 text-lg leading-relaxed text-gray-600">
                                  We help <b>tech companies</b> evaluate candidates’ <b>technical skills</b> by providing highly professional <b>interviewing services</b>
                              </p>
                              <ul className="list-none mt-6">
                                  <Item icon="fas fa-fingerprint" text="Privacy guarantee"/>
                                  <Item icon="fas fa-hand-holding-usd" text="Save money"/>
                                  <Item icon="fab fa-html5" text="Broaden your recruiting possibilities"/>
                                  <Item icon="far fa-paper-plane" text="Instant reliable report"/>
                                  <Item icon="far fa-clock" text="Reduce time-to-hire from months to days"/>
                                  <Item icon="fas fa-users" text="Diverse interviewers from a variety of companies"/>
                                  <Item icon="fas fa-user-lock" text="Freeing your employees to concentrate on main business needs"/>
                                  <Item icon="fas fa-hippo" text="Unbiased evaluation of your candidate by highly skilled professionals"/>
                                  <Item icon="fas fa-fighter-jet" text="Filter out unsuitable candidates"/>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="pt-20 pb-48">
              <div className="container mx-auto px-4">
                  <div className="flex flex-wrap justify-center text-center mb-24">
                      <div className="w-full lg:w-6/12 px-4">
                          <h2 className="text-4xl font-semibold">The Founder</h2>
                          <p className="text-lg leading-relaxed m-4 text-gray-600">
                              <b>Liron Mor-Yosef</b> is experienced Algorithm and Software Engineer with a demonstrated history of creating core operations from scratch.
                          </p>

                          <p className="text-lg leading-relaxed m-4 text-gray-600">
                              Strong engineering, professionally skilled in Python, C/C++, JAVA, .NET, MATLAB, Video/Image Processing, modern Computer Vision, and AI.
                          </p>
                          {/*<p className="text-lg leading-relaxed m-4 text-gray-600">*/}
                          {/*    Prof. <b>Haim Avron</b> is an associate professor of computer science and applied mathematics at Tel Aviv University.*/}
                          {/*</p>*/}
                      </div>
                  </div>
                  <div className="flex flex-wrap container ">
                      <CardPeople
                          name="Liron Mor Yosef"
                          position="Founder & CEO @ QualiiFly"
                          img={require("assets/img/team/liron.jpg")}
                          linkedin="https://www.linkedin.com/in/liron-mor-yosef-624b461a9"
                          mail="liron@qualiifly.com"/>
                      {/*<CardPeople*/}
                      {/*    name="Prof. Haim Avron"*/}
                      {/*    position="Science Advisor & CSO @ QualiiFly"*/}
                      {/*    img={require("assets/img/team/haim.jpg")}*/}
                      {/*    linkedin="https://www.linkedin.com/in/haim-avron-1716102/"*/}
                      {/*    mail="haim@qualiifly.com"/>*/}


                  </div>
              </div>
          </section>
          {/*########################START#######################*/}

          <section className="pb-20 relative block bg-gray-900">
              <div
                  className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                  style={{ transform: "translateZ(0)" }}
              >
                  <svg
                      className="absolute bottom-0 overflow-hidden"
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="none"
                      version="1.1"
                      viewBox="0 0 2560 100"
                      x="0"
                      y="0"
                  >
                      <polygon
                          className="text-gray-900 fill-current"
                          points="2560 0 2560 100 0 100"
                      ></polygon>
                  </svg>
              </div>
              <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
                  <div className="flex flex-wrap text-center justify-center">
                      <div className="w-full lg:w-6/12 px-4">
                          <h2 className="text-4xl font-semibold text-white">
                              Let Us Join our Shared Success
                          </h2>
                          <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                              Our technology will assign qualified interviewers for you, to help you find the right candidate-- fast.
                          </p>
                      </div>
                  </div>
                  <div className="flex flex-wrap mt-12 justify-center">
                      <div className="w-full lg:w-3/12 px-4 text-center">
                          <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                              <i className="fas fa-medal text-xl"></i>
                          </div>
                          <h6 className="text-xl mt-5 font-semibold text-white">
                              Excellent Services
                          </h6>
                          <p className="mt-2 mb-4 text-gray-500">
                              We are committed to uncompromising quality- nothing short of perfect! Money back guarantee.
                          </p>
                      </div>
                      <div className="w-full lg:w-3/12 px-4 text-center">
                          <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                              <i className="fas fa-poll text-xl"></i>
                          </div>
                          <h5 className="text-xl mt-5 font-semibold text-white">
                              Trust and Credentials
                          </h5>
                          <p className="mt-2 mb-4 text-gray-500">
                              We are committed to the highest level of interviewers on the market; just ask our happy customers!

                          </p>
                      </div>
                      <div className="w-full lg:w-3/12 px-4 text-center">
                          <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                              <i className="fas fa-lightbulb text-xl"></i>
                          </div>
                          <h5 className="text-xl mt-5 font-semibold text-white">
                              Test us
                          </h5>
                          <p className="mt-2 mb-4 text-gray-500 ">
                              We invite you to interview our interviewers to get a better understanding of how professional they really are (free of charge).
                          </p>
                      </div>
                  </div>
              </div>
          </section>

          <section className="relative block py-40 lg:pt-0 bg-gray-900">
            <SubmitForm />
          </section>


          {/*#################################*/}

      </main>
  );

};

export default Main;