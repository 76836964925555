import React from "react"


const PagingButtons = (props) => {
    const {gotoPage, canPreviousPage, previousPage, nextPage, canNextPage, pageCount, pageIndex, pageOptions, pageSize, setPageSize} = props;
    const buttonClassName = "bg-gray-700 active:bg-blue-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-1 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150";

    return (<div className="float-center">
        <button
            className={buttonClassName}
            type="button"
            onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
        </button>
        {' '}
        <button
            className={buttonClassName}
            type="button"
            onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
        </button>
        {' '}
        <button
            className={buttonClassName}
            type="button"
            onClick={() => nextPage()} disabled={!canNextPage}>
            Next
        </button>
        {' '}
        <button
            className={buttonClassName}
            type="button"
            onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
        </button>
        {' '}
        <span>Page{' '}<strong>{pageIndex + 1} of {pageOptions.length}</strong>{' '}</span>
        <span>| Go to page:{' '}
            <input
                className="bg-transparent w-10 text-gray-800 "
                type='number'
                defaultValue={pageIndex + 1}
                onChange={e => {
                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(pageNumber)
                }}
                // style={{width: '50px'}}
            /></span>{' '}
        <select
            value={pageSize}
            onChange={e => setPageSize(Number(e.target.value))}>
            {[2, 10, 25, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                </option>
            ))}
        </select>
    </div>);

}

export default PagingButtons;