import React from 'react';
import Dropzone from 'react-dropzone'
import FormField from "./FormField";
import {Field} from "redux-form";


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


class FormFieldImage extends FormField {
    state = {files: []}
    // const [files, setFiles] = useState([]);
    onDrop = acceptedFiles => {
        console.log("on drop")
        this.setState(() => ({
            files: acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
        }))
    };

    getThumbs = (files) => {
        return files.map(file => (
            <div key={file.path} className="align-right">
                <img
                    referrerPolicy="no-referrer"
                    alt="uploaded"
                    className="w-full rounded-full align-right border-none shadow-lg"
                    src={file.preview}
                />
            </div>
        ));
    }

    componentDidMount() {
        this.state.files.forEach(file => URL.revokeObjectURL(file.preview));
    }

    renderParagraph = (isDragActive, fileRejections) => {
        const isDragReject = fileRejections.length > 0;
        if (!isDragActive) {
            if (this.state.files.length === 0) {
                return <p>Click here or drop a image to upload!</p>
            } else {
                return null
            }
        } else if (isDragActive && !isDragReject) {
            return <p>Drop it like it's hot!</p>
        } else if (isDragReject) {
            console.log("ddd")
            alert(fileRejections[0].errors)
            return <p>File type not accepted, sorry!</p>
        } else {
            throw Error("should not get here")
        }
    };

    renderDropZone = (input, files) => {
        const maxSize = 2097152;
        return (<Dropzone
                onDrop={this.onDrop}
                accept="image/*"
                minSize={0}
                maxSize={maxSize}
            >
                {({getRootProps, getInputProps, isDragActive, isDragReject,fileRejections  }) => {
                    return (
                    <div className="bg-white" {...getRootProps()}>
                        <input {...input} {...getInputProps()} />
                        {this.renderParagraph(isDragActive, fileRejections)}
                        <aside style={thumbsContainer}>
                                {this.getThumbs(files)}
                        </aside>
                    </div>
                )}}
            </Dropzone>
        );
    }
    renderInput = ({input, label, meta, type, files}) => {
        // const className = `${classNameInput} ${meta.error && meta.touched? 'error': ''}`;
        return (

            <div className="relative w-full mb-3">
                <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                >
                    {label}
                </label>


                {this.renderDropZone(input, files)}
            </div>
        );

    }

    render() {
        const {globalLayout, name, layout, type} = this.props;
        const layoutToUse = (layout ? layout : globalLayout)
        return (
            <div className={`w-full lg:w-${layoutToUse} px-4`}>
                <Field name={name}
                       label={this.convertUnderScoreSentenceCase(name)}
                       component={this.renderInput}
                       type={type}
                       files={this.state.files}
                />
            </div>
        );
    }


}

export default FormFieldImage;
//
// export default reduxForm({
//     form: 'simple',
// })(FormFieldImageUpload);