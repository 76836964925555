import React from "react"
import {mapping} from "./mapping";



const Factory =  (props)=>{
    const {type} = props
    if(mapping.hasOwnProperty(type)){
        return (
            React.createElement(mapping[type], {...props, key:{type}})
        );
    }
    else{
        console.log("failed to find type ", type)
        return null
    }

}

export default Factory;