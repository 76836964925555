import React from "react"

const CardPeople = (props)=>{

    const {name, position, img, linkedin, mail} = props;
    return(
        <div className="w-full md:w-12/12 lg:w-12/12 lg:mb-0 mb-12 ">
            <div className="container mx-auto ">
                <img
                    alt="..."
                    src={img.default}
                    className="shadow-lg rounded-full mx-auto max-w-320-px"
                />
                <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">{name}</h5>
                    <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                        {position}
                    </p>
                    <div className="mt-6">
                        <button
                            className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                        >
                            <a href={linkedin}>
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                        </button>
                        <button
                            className="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                        >
                            <a
                                href={`mailto:${mail}`}
                            ><i className="fas fa-envelope"></i></a>
                        </button>
                        {/*<button*/}
                        {/*    className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"*/}
                        {/*    type="button"*/}
                        {/*>*/}
                        {/*    <i className="fab fa-dribbble"></i>*/}
                        {/*</button>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardPeople;