import React from "react"
import {Link} from "react-router-dom";

const PageElement = (props)=>{
    const {to, displayName, onPopoverClicked} = props
    return (
        <Link
            onClick={(e)=>onPopoverClicked(e, false)}
            to={to}
            className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
            }
        >
            {displayName}
        </Link>
    );
};


export default PageElement;