import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CardImage from "../../../components/Cards/CardImage";

export default class DemoHome extends Component {
    render() {
        return (
            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="container mx-auto px-4">
                    <h1 className="font-bold text-3xl text-gray-800 my-2">
                        QUALIIFLY Demo{" "}
                        <span role="img" aria-label="Basketball"></span>
                    </h1>
                    {/*<h2 className="text-gray-800 font-semibold text-6xl">*/}
                    {/*    QUALIIFLY Demo Home Page</h2>*/}
                    <div className="flex flex-wrap">
                        <CardImage title="Facial Recognition" subTitle="Face API JS" to="/demo/camera" img={require("assets/img/demo/face.jpg").default} />
                        <CardImage title="Lie Detector Test" subTitle="IBM Watson Ecosystem" to="/demo/camera" img={require("assets/img/demo/lie.jpg").default} />
                        <CardImage title="Voice Recognition" subTitle="Face API" to="/demo/voice" img={require("assets/img/demo/voice.jpg").default} />
                        {/*<CardImage title="Facial Recognition" subTitle="Face API" to="/demo/camera" img={require("assets/img/demo/face-algorithm.jpg").default} />*/}
                    </div>
                </div>
            </div>
        );
    }
}
