import React from 'react';

import {Field,  change as changeFieldValue} from "redux-form";
import FormField from "./FormField";
import MultiSelect from "./MultiSelect";


class FormFieldMultiSelect extends FormField {

    // onChange = function(event, newValue, previousValue, name) {
    //     this.props.dispatch(changeFieldValue('myFormName', 'anotherField', 'value'));
    // }

    render() {
        const {globalLayout, name, layout, type} = this.props;
        const layoutToUse = (layout ? layout : globalLayout)
        return (
            <div className={`w-full lg:w-${layoutToUse} px-4`}>
                <Field name={name}
                       label={this.convertUnderScoreSentenceCase(name)}
                       component={MultiSelect}
                       type={type}
                       // onBlur={() => this.props.onBlur()}
                       defaultValue={[]}

                    // onChange={(value) => {return props.input.onChange(value.map(p => p.value))}}
                       // onBlur={() => props.input.onBlur([...props.input.value])}
                       // options={props.options}
                       // multi={true}
                />
            </div>
        );
    }

}

export default FormFieldMultiSelect;