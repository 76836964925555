import React from "react";
import {createPopper} from "@popperjs/core";
import PageDropdownList from "./Pages/PageDropdownList";
import BorderDropdown from "./Pages/BorderDropdown";
import {Link} from "react-router-dom";
import Tables from "../../views/admin/Tables";


const UserDropdown = ({profile, onSignOutClick}) => {
    // dropdown props
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
    const btnDropdownRef = React.createRef();
    const popoverDropdownRef = React.createRef();
    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
            placement: "bottom-start",
        });
        setDropdownPopoverShow(true);
    };
    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };

    const onPopoverClicked = (e, preventDefault = true) => {
        if (preventDefault) {
            e.preventDefault();
        }

        dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
    };
    return (
        <>
            <a
                className="text-gray-600 block"
                href="#pablo"
                ref={btnDropdownRef}
                onClick={onPopoverClicked}
            >
                <div className="items-center flex">
          <span
              className="w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full">
            {/*<img*/}
            {/*    referrerPolicy="no-referrer"*/}
            {/*    // alt={profile.fullName}*/}
            {/*    className="w-full rounded-full align-middle border-none shadow-lg"*/}
            {/*    // src={profile.imageURL}*/}
            {/*/>*/}
          </span>
                </div>
            </a>
            <div
                ref={popoverDropdownRef}
                className={
                    (dropdownPopoverShow ? "block " : "hidden ") +
                    "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
                }
            >
                <Link to="/user/profile" onClick={(e) => onPopoverClicked(e, false)}>
                    <span className={
                        "text-xs py-2 px-4 font-normal  w-full whitespace-no-wrap bg-transparent text-gray-800"
                    }>
            {"profile.fullName"}</span>
                    <span
                        className="m-4 w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full">
            {/*<img*/}
            {/*    referrerPolicy="no-referrer"*/}
            {/*    alt={profile.fullName}*/}
            {/*    className="w-full rounded-full align-middle border-none shadow-lg"*/}
            {/*    src={profile.imageURL}*/}
            {/*/>*/}

          </span>

                </Link>

                <PageDropdownList section="Account"
                                  root="/recruiter"
                                  arrayOfRouts={[{path: 'dashboard', displayName: "Dashboard"},
                                      {path: 'settings', displayName: "Settings"},
                                      {path: 'tables', displayName: "Tables"},
                                      {path: 'maps', displayName: "Maps"}]}
                                  onPopoverClicked={onPopoverClicked}/>

                <BorderDropdown/>

                {/*<PageDropdownList root="auth" arrayOfRouts={[{path: 'login', displayName: "Login"},*/}
                {/*  {path: 'register', displayName: "Register"}]}/>*/}

                {/*<BorderDropdown/>*/}

                {/*{path: 'landing', displayName: "Landing"},*/}
                <PageDropdownList section="User"
                                  root="/user"
                                  arrayOfRouts={[{path: 'account-settings', displayName: "Account Settings"},{path: 'user-profile', displayName: "Profile"},
                                      {path: 'edit-profile', displayName: "Edit Profile"}]}
                                  onPopoverClicked={onPopoverClicked}/>
                <div className="h-0 my-2 border border-solid border-gray-200"/>
                <a
                    href="#pablo"
                    className={
                        "text-sm py-2 px-4 font-bold block w-full whitespace-no-wrap bg-transparent text-red-500"
                    }
                    onClick={onSignOutClick}
                >
                    Sign Out
                </a>
            </div>
        </>
    );
};

export default UserDropdown;
