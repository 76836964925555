import React from "react";
// components

import Footer from "components/Footers/Footer.js";
import Main from "./Main";
import Navbar from "../../components/Navbars/AuthNavbar";
import {Router} from "react-router-dom";
import {NotificationContainer} from "react-notifications";

export default function Landing() {
  return (
    <>
        <Navbar transparent />

        <Main />
      <Footer />
        <NotificationContainer />
    </>
  );
}
