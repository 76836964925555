import {CREATE_PROFILE, FETCH_PROFILE} from "../../actions/types";


// eslint-disable-next-line
export default (state = {}, action) => {
    console.log(action.type)
    switch (action.type) {
        case CREATE_PROFILE:
        case FETCH_PROFILE:
            return {...state, data: action.payload};
        default:
            return state
    }
}