import React, {Component} from "react";
import {connect} from "react-redux";
import {setCookie, LOGGEDIN_COOKIE, TOKEN_COOKIE} from "services/cookies.js";
import {tryRegister, registerReset} from "redux/auth/Actions.js";
import {reduxForm} from "redux-form";
import Snackbar from "./Snackbar";


class RegisterForm extends Component {

    state = {
        cardAnimaton: "cardHidden",
        username: "",
        email: "",
        password: "",
        password_confirmation: "",
        checkbox: false,
        place: 'tr',
        notificationCount: 0,
        notificationMessage: '',
    };

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }




    handleSubmit = event => {
        event.preventDefault();
        if(this.state.checkbox){
            const {username, password, email, checkbox} = this.state;
            this.props.tryRegister(username, email, password);
        }else{
            this.setState(prevState => {
                return {
                    notificationCount: prevState.notificationCount + 1,
                    notificationType: 'warning',
                    notificationMessage: {message:"You must agree with the Privacy Policy", field: "checkbox"}
                }
            })
            setTimeout(function () {
                this.setState({show: false});
            }.bind(this), 1500);
        }

    }


    // componentDidMount() {
    //     const {history} = this.props;
    //     const {api_token, loggedIn} = this.props.auth;
    //
    //     if (loggedIn) {
    //         console.log("2")
    //
    //         setCookie(TOKEN_COOKIE, api_token, {path: "/"});
    //         setCookie(LOGGEDIN_COOKIE, loggedIn, {path: "/"});
    //         history.push("/"); // Home?
    //     }
    // }

    componentDidUpdate(prevProps, prevState) {

        const {history} = this.props;
        const {registerInProgress, registerHasError, registerCompleted, api_token, loggedIn, registerError} = this.props.auth;
        console.log("Enter", registerInProgress , registerHasError , registerCompleted, loggedIn)

        if (loggedIn & !registerInProgress && !registerHasError && !registerCompleted) {
            console.log("loggedIn")

            setCookie(TOKEN_COOKIE, api_token, {path: "/"});
            setCookie(LOGGEDIN_COOKIE, loggedIn, {path: "/"});
            history.push("/"); // Home?

        }else if (registerInProgress && !registerHasError && !registerCompleted) {
            console.log("1")

        } else if (!registerInProgress && registerHasError && registerCompleted) {

            if (loggedIn && !registerHasError) {
                console.log("2")

                setCookie(TOKEN_COOKIE, api_token, {path: "/"});
                setCookie(LOGGEDIN_COOKIE, loggedIn, {path: "/"});
                history.push("/"); // Home?
            } else {
                console.log("3")
                this.setState(prevState => {
                    return {
                        notificationCount: prevState.notificationCount + 1,
                        notificationType: 'error',
                        notificationMessage: registerError
                    }
                })
                setTimeout(function () {
                    this.setState({show: false});
                }.bind(this), 1500);
            }
            this.props.registerReset();
        }else if (!registerInProgress && !registerHasError && registerCompleted) {
            console.log("4")

            this.props.registerReset();
        }
    }


    render() {
        return (
            <>
                {/*<div className="container mx-auto px-4 h-full">*/}
                {/*    <div className="flex content-center items-center justify-center h-full">*/}
                {/*        <div className="w-full lg:w-6/12 px-4">*/}
                {/*            <FormBuilder onSubmit={this.props.handleSubmit(this.handleSubmit)}*/}
                {/*                         formConfs={CreateAccount}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="container mx-auto px-4 h-full">
                    <div className="flex content-center items-center justify-center h-full">
                        <div className="w-full lg:w-6/12 px-4">
                            <div
                                className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
                                <div className="rounded-t mb-0 px-6 py-6">
                                    <div className="text-center mb-3">
                                        <h6 className="text-gray-600 text-sm font-bold">
                                            Sign up with
                                        </h6>
                                    </div>
                                    {/*<div className="btn-wrapper text-center">*/}
                                    {/*    <button*/}
                                    {/*        className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"*/}
                                    {/*        type="button"*/}
                                    {/*    >*/}
                                    {/*        <img*/}
                                    {/*            alt="..."*/}
                                    {/*            className="w-5 mr-1"*/}
                                    {/*            src={require("assets/img/github.svg").default}*/}
                                    {/*        />*/}
                                    {/*        Github*/}
                                    {/*    </button>*/}
                                    {/*    <button*/}
                                    {/*        className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"*/}
                                    {/*        type="button"*/}
                                    {/*    >*/}
                                    {/*        <img*/}
                                    {/*            alt="..."*/}
                                    {/*            className="w-5 mr-1"*/}
                                    {/*            src={require("assets/img/google.svg").default}*/}
                                    {/*        />*/}
                                    {/*        Google*/}
                                    {/*    </button>*/}
                                    {/*    /!*<GoogleButton/>*!/*/}
                                    {/*</div>*/}
                                    {/*<hr className="mt-6 border-b-1 border-gray-400"/>*/}
                                </div>
                                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                    {/*<div className="text-gray-500 text-center mb-3 font-bold">*/}
                                    {/*    <small>Or sign up with credentials</small>*/}
                                    {/*</div>*/}
                                    <form onSubmit={this.handleSubmit1}>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Username
                                            </label>
                                            <input
                                                type="text"
                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                placeholder="Name"
                                                name="username"
                                                value={this.state.username}
                                                onChange={this.handleChange}
                                            />
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                name="email"
                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                placeholder="Email"
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                            />
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Password
                                            </label>
                                            <input
                                                type="password"
                                                name="password"
                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                placeholder="Password"
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                            />
                                        </div>

                                        <div>
                                            <label className="inline-flex items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    name="checkbox"
                                                    className="form-checkbox text-gray-800 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                    checked={this.state.checkbox}
                                                    onChange={this.handleChange}
                                                />
                                                <span className="ml-2 text-sm font-semibold text-gray-700">
                        I agree with the{" "}
                                                    <a
                                                        href="#pablo"
                                                        className="text-blue-500"
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                          Privacy Policy
                        </a>
                      </span>
                                            </label>
                                        </div>

                                        <div className="text-center mt-6">
                                            <button
                                                className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={event => this.handleSubmit(event)}
                                            >
                                                Create Account
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <Snackbar notificationCount={this.state.notificationCount}
                          notificationType={this.state.notificationType}
                          notificationMessage={this.state.notificationMessage}/>
            </>
        );
    }
}

const validate = formValues => {
    const errors = {};
    if (!formValues.username) {
        errors.username = 'You must enter a username';
    }
    return errors
};

RegisterForm = reduxForm({
    form: 'createAccountForm',
    enableReinitialize: true,
    destroyOnUnmount: true,
    keepDirtyOnReinitialize: true,
    validate
})(RegisterForm)


const bindAction = dispatch => {
    return {
        tryRegister: (username, email, password, full_name) => dispatch(tryRegister(username, email, password, full_name)),
        registerReset: () => dispatch(registerReset())
    };
}

const mapStateToProps = state => ({
    auth: state.auth
});


export default connect(mapStateToProps, bindAction)(RegisterForm);
