import React from "react"

const ImageOnly = (props) => {

    const { imageSrc} = props
    const getImage = (url) => {
        return require(`${url}`)
    }
    return (
        <div className="flex">
            <img
                src={imageSrc}
                alt="..."
                className="w-10 h-10 rounded-full border-2 border-gray-100 shadow"
            />
        </div>
    );

};

export default ImageOnly;