import React from "react";

import {connect} from 'react-redux'
// components

import {reduxForm} from "redux-form";
import {companySettings} from "../../../components/FormFactory/formConfs";
import FormBuilder from "../../../components/FormFactory/FormBuilder";
import allActions from "../../../actions";

let CompanyForm = (props) => {
    const onSubmit = (formValues) => {
        // e.preventDefault()
        console.log("onSubmit", formValues)
        //props.createProfile(formValues)
    }
    return (
        <>
                <div className="flex content-center items-center flex-wrap">
                    <div className="container mx-auto w-full lg:w-6/12  px-4">
                        <div
                            className="content-center items-center  h-full w-full px-4 relative flex flex-col min-w-0  w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0 rounded-t mb-0 px-6 py-6">
                            <FormBuilder onSubmit={props.handleSubmit(onSubmit)} formConfs={companySettings}/>
                        </div>
                    </div>
                    {/*<div className="w-full lg:w-4/12 px-4">*/}
                    {/*  <CardProfile />*/}
                    {/*</div>*/}
                </div>
        </>
    );
}

const validate = formValues => {
    const errors = {};
    if (!formValues.username) {
        errors.username = 'You must enter a username';
    }
    return errors
};

const mapStateToProps = (state, ownProps) => {
    return {
        initialValues: {
            ...state.auth.qualiifly.login,
            email: state.auth.qualiifly.email,
            given_name: state.auth.qualiifly.given_name,
            family_name: state.auth.qualiifly.family_name,
            // roll: 2
        }
    }
};

CompanyForm = reduxForm({
    form: 'editAccountForm',
    enableReinitialize: true,
    destroyOnUnmount: true,
    keepDirtyOnReinitialize: true,
    validate
})(CompanyForm)

// You have to connect() to any reducers that you wish to connect to yourself
CompanyForm = connect(mapStateToProps, {
        fetchProfile: allActions.profileActions.fetchProfile,
        createProfile: allActions.profileActions.createProfile
    } // bind account loading action creator
)(CompanyForm)


export default CompanyForm
