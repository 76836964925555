import React from 'react';
import _ from "lodash"

import Dropdown from './Dropdown';


class MultiSelect extends React.Component {
    // state showing if dropdown is open or closed
    state = {dropdown: false, items: ['john', 'milos', 'steph', 'kathreine'], selectedItems: []}


    toogleDropdown = (e) => {
        this.setState((prevState) => ({
            dropdown: !prevState.dropdown
        }));
    };
    // adds new item to multiselect
    addTag = (item) => {
        this.setState((prevState) => ({
            selectedItems: prevState.selectedItems.concat(item)
        }));
        this.setState({dropdown: false})
    };
    // removes item from multiselect
    removeTag = (item) => {
        const filtered = this.state.selectedItems.filter((e) => e !== item);
        this.setState({selectedItems: filtered})

    }

    renderItem = (tag, index) => {
        return (
            <div key={index}
                 className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-teal-700 bg-teal-100 border border-teal-300 ">
                <div className="text-xs font-normal leading-none max-w-full flex-initial">{tag}</div>
                <div className="flex flex-auto flex-row-reverse">
                    <div onClick={() => this.removeTag(tag)} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                             strokeLinejoin="round"
                             className="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-5 h-5 ml-2">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </div>
                </div>
            </div>)
    }

    renderSelectedItems = () => {
        return this.state.selectedItems.map((tag, index) => this.renderItem(tag, index));
    }

    renderDropdown = (remainItems)=>{
        if(this.state.dropdown) {
            return (<Dropdown list={remainItems} addItem={this.addTag}></Dropdown>);
        }else{
            return null
        }

    }
    renderMultiSelect = (input) => {
        const remainItems = _.difference(this.state.items, this.state.selectedItems)
        return (<div className="autcomplete-wrapper">
            <div className="autcomplete">
                <div className="w-full flex flex-col items-center mx-auto">
                    <div className="w-full">
                        <div className="flex flex-col items-center relative">
                            <div className="w-full ">
                                <div className="my-2 p-1 flex border border-gray-200 bg-white rounded ">
                                    <div className="flex flex-auto flex-wrap">
                                        {this.renderSelectedItems()}
                                        <div className="flex-1">
                                            <input {...input} placeholder=""
                                                   className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800"
                                                   autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
                                        onClick={this.toogleDropdown}>
                                        <button
                                            type="button"
                                            className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
                                                 fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-chevron-up w-4 h-4">
                                                <polyline points="18 15 12 9 6 15"></polyline>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.renderDropdown(remainItems)}
                    </div>
                </div>

            </div>
        </div>);

    };

    // const {globalLayout, name, layout, type, readonly} = this.props;
    // const layoutToUse = (layout ? layout : globalLayout)
    // return (
    //     <Field name={name}
    //            label={this.convertUnderScoreSentenceCase(name)}
    //            component={renderInput}
    //            type={type}
    //            readonly={readonly}
    //     />
    // );

    render() {
        const {input, label, meta, type, onChange} = this.props;
        // input.onChange = (value) => {
        //     console.log("onChange", value)
        //     console.log("onChange", this.state.selectedItems)
        //     return input.onChange(this.state.selectedItems)
        // }
        // input.onBlur = () => input.onBlur([...input.value])
        return (

            <div className="relative w-full mb-3">
                <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                >
                    {label}
                </label>


                {this.renderMultiSelect(input)}
            </div>
        );
    }

}


export default MultiSelect;