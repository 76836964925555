import React from "react";
import {Switch, Route, Redirect, Router} from "react-router-dom";

// components
import history from "../history";

// views
import Navbar from "../components/Navbars/AuthNavbar";
import TopBackground from "../components/Headers/TopBackground";
import ExaminationPlatformHome from "../views/demo/views/ExaminationPlatformHome";
import QuizCSS from "../views/demo/views/quiz/QuizCSS";

class ExaminationPlatform extends React.Component {
    render() {
        return (
            <>
                <Navbar transparent />
                <TopBackground />
                {/*<Router history={createHistory({ basename: process.env.PUBLIC_URL })}>*/}


                {/*<div className="relative md:ml-64 bg-gray-200">*/}
                {/*    <div className="px-4 md:px-10 mx-auto w-full -m-24">*/}
                        <Switch>
                            <Route history={history} path="/examination-platform/home" exact component={ExaminationPlatformHome}/>
                            <Route history={history} path="/examination-platform/css" exact component={QuizCSS}/>
                            {/*<Route history={history} path="/examination-platform/Voice" exact component={VoiceInput}/>*/}
                            <Redirect from="/examination-platform" to="/examination-platform/home"/>
                        </Switch>
                {/*    </div>*/}
                {/*</div>*/}
            </>
        );
    }


}


export default ExaminationPlatform;