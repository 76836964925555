import {FETCH_PROFILE, CREATE_PROFILE} from "./types";

import qualiiflyApi, {authHeader} from "../apis/qualiiflyApi";
import history from "../history";


const fetchProfile = () => async  (dispatch, getState) => {
    console.log("fetchProfile")
    const id = getState().auth.qualiifly.profile_id;
    if(id){
        const response = await qualiiflyApi.get(`/profiles/${id}`, { headers: authHeader() });
        dispatch({type: FETCH_PROFILE, payload: response.data});
    }

};


const createProfile = formValues => async (dispatch, getState) => {
    console.log("in create profile", formValues)
    const response = await qualiiflyApi.post('/profiles', {...formValues }, { headers: authHeader() });
    dispatch({type: CREATE_PROFILE, payload: response.data});
    if(response.data.roll === 1){
        history.push('/recruiter/dashboard');
    }
    else {
        history.push('/');
    }

};


// eslint-disable-next-line
export default {
    fetchProfile,
    createProfile
}