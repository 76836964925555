import React from "react"
import history from "../../../history";
import {connect} from "react-redux"

import {Link} from "react-router-dom";
import allActions from "../../../actions";
import Modal from "../../../components/Modal";


class CompanyDelete extends React.Component {

    componentDidMount() {
        this.props.fetchCompany(this.props.match.params.id);
    }

    renderActions = () => {
        const {id} = this.props.match.params;
        return (
            <React.Fragment>
                <button onClick={() => this.props.deleteCompany(id)} className="ui button negative">Delete</button>
                <Link to="/" className="ui button">Cancel</Link>
            </React.Fragment>
        )
    }

    renderContent() {
        if (!this.props.company) {
            return "Are you sure you want to delete this company?";
        }

        return `Are you sure you want \
         to delete the company with title: ${this.props.company.title}?`;
    }

    render() {
        return (
            <div>CompanyDelete
                <Modal
                    title="Delete Company"
                    content={this.renderContent()}
                    actions={this.renderActions()}
                    onDismiss={() => history.push('/')}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {

    return {company: state.companies[ownProps.match.params.id]};
};

export default connect(mapStateToProps, {fetchCompany: allActions.companyActions.fetchCompany, deleteCompany: allActions.companyActions.deleteCompany})(CompanyDelete);