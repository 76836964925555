import React from "react"
import FormField from "./FormField";


class FormFieldTextArea extends FormField {


    renderInputHtmlType = (input, label, fieldType, readonly) => {
        const {htmlType, type, row} = this.props.globalInputType;
        const typeToUse = (fieldType ? fieldType : type)
        return (
            <textarea
                {...input}
                type={typeToUse}
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                rows={row}
            />
        );


    }
}

export default FormFieldTextArea;