import React from "react"
import CardInterviewer from "../components/Cards/CardInterviewer";
import TopBackground from "../components/Headers/TopBackground";
import Navbar from "../components/Navbars/AuthNavbar";
import {Router} from "react-router-dom";

class InterviewerMarketplace extends React.Component{

    render() {
        return(
            <React.Fragment>
                <Navbar transparent />

                <main className="interviewer-marketplace-page">
                    <TopBackground />
                    <section className="pt-20 pb-48" >
                <div className="container mx-auto px-4" >
                    <div className="flex flex-wrap justify-center text-center mb-24">
                        <div className="w-full lg:w-6/12 px-4">
                            <h2 className="text-lg leading-6 font-medium text-black">Interviewers</h2>
                        </div>
                    </div>
                    <form className="relative">
                        <svg width="20" height="20" fill="currentColor"
                             className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"/>
                        </svg>
                        <input
                            className="focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10"
                            type="text" aria-label="Find interviewer" placeholder="Find interviewer"/>
                    </form>
                    <div className="flex flex-wrap">
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                        <CardInterviewer />
                    </div>
                </div>
            </section>
                </main>
            </React.Fragment>
        );
    }

}

export default InterviewerMarketplace;