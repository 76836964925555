import React from "react"
import {mapping} from "./mapping";



const Factory =  (props)=>{
    const {htmlType} = props
    if(mapping.hasOwnProperty(htmlType)){
        return (
            React.createElement(mapping[htmlType], {...props})
        );
    }
    else{
        console.log("failed to find type ", htmlType)
        return null
    }

}

export default Factory;