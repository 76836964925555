import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";

// components

import Navbar from "components/Navbars/AuthNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import HeaderStats from "components/Headers/HeaderStats";
import FooterAdmin from "components/Footers/FooterAdmin";

// views

import AccountSettings from "views/user/AccountSettings";
import Tables from "views/admin/Tables.js";
import RecruiterCompany from "../views/admin/RecruiterCompany";

class Recruiter extends React.Component {
    render() {
        return (
            <>
                <Sidebar />
                <div className="relative md:ml-64 bg-gray-200">
                    <Navbar />
                    {/* Header */}
                    <HeaderStats/>
                    <div className="px-4 md:px-10 mx-auto w-full -m-24">
                        <Switch>
                            {/*view*/}
                            <Route path="/recruiter/company" component={RecruiterCompany}/>


                            <Route path="/recruiter/dashboard" exact component={Tables}/>
                            <Route path="/recruiter/settings" exact component={AccountSettings}/>
                            <Route path="/recruiter/tables" exact component={Tables}/>
                            <Redirect from="/recruiter" to="/recruiter/dashboard"/>
                        </Switch>
                        <FooterAdmin/>
                    </div>
                </div>
            </>
        );
    }


}


export default Recruiter;