import React from "react"

const ImageAndText = (props) => {

    const {color, text, imageSrc} = props
    return (
        <div className="text-left flex items-center">
            <img
                src={imageSrc}
                className="h-12 w-12 bg-white rounded-full border"
                alt="..."
            />{" "}
            <span
                className={
                    "ml-3 font-bold " +
                    +(color === "light" ? "text-gray-700" : "text-white")
                }
            >
                {text}
            </span>
        </div>
    );

};

export default ImageAndText;