import React from "react"
import {featuresCard} from "./landingConfs";
import FeatureCard from "./FeatureCard";

class FeaturesList extends React.Component{

    renderCards = () => {
        return featuresCard.map(featureCardConf => (
                <FeatureCard  key={featureCardConf.title} {...featureCardConf} />
            )
        );
    }
    render() {
        return(
            <div className="flex flex-wrap">
                {this.renderCards()}

            </div>
        );
    }
}

export default FeaturesList;