import React, {Component} from "react";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';


class Snackbar extends Component{
    state = {counter:0}
    render() {
        const {notificationCount, notificationType, notificationMessage} = this.props

        const createNotification = (notificationType) => {

            switch (notificationType) {
                case 'info':
                    NotificationManager.info(notificationMessage.message);
                    break;
                case 'success':
                    NotificationManager.success(notificationMessage.message, 'Success');
                    break;
                case 'warning':
                    NotificationManager.warning(notificationMessage.message, 'Warning', 2300);
                    break;
                case 'error':
                    NotificationManager.error(notificationMessage.message, 'Error', 2300);
                    break;
            }
        }
        console.log(this.state.counter , notificationCount)
        if (this.state.counter < notificationCount) {
            this.setState(prevState => {
                return {
                    counter: Math.max(prevState.counter + 1, notificationCount)
                }
            })
            createNotification(notificationType)
        }

        return (<NotificationContainer />);
    }


}


export default Snackbar;
