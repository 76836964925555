import React from "react";
import {Switch, Route, Redirect, Router} from "react-router-dom";

// components
import history from "../history";

// views
import Navbar from "../components/Navbars/AuthNavbar";
import DemoHome from "../views/demo/views/DemoHome";
import VideoInput from "../views/demo/views/VideoInput";
import TopBackground from "../components/Headers/TopBackground";
import VoiceInput from "../views/demo/views/VoiceInput";

class Demo extends React.Component {
    render() {
        return (
            <>
                <Navbar transparent />
                <TopBackground />
                {/*<Router history={createHistory({ basename: process.env.PUBLIC_URL })}>*/}


                {/*<div className="relative md:ml-64 bg-gray-200">*/}
                {/*    <div className="px-4 md:px-10 mx-auto w-full -m-24">*/}
                        <Switch>
                            <Route history={history} path="/demo/home" exact component={DemoHome}/>
                            <Route history={history} path="/demo/camera" exact component={VideoInput}/>
                            <Route history={history} path="/demo/Voice" exact component={VoiceInput}/>
                            <Redirect from="/demo" to="/demo/home"/>
                        </Switch>
                {/*    </div>*/}
                {/*</div>*/}
            </>
        );
    }


}


export default Demo;