import React, {useMemo, useState, useEffect} from "react";
import {useTable, useGlobalFilter, useSortBy, useRowSelect, usePagination} from "react-table";
import {COLUMNS, DATA, DEFAULT_COLUMN, INNER_TEXT} from "./columns";
import Checkbox from "./components/Checkbox";
import TableTitle from "./components/TableTitle";
import GlobalFilter from "./components/GlobalFilter";
import PagingButtons from "./components/PagingButtons";
import PlusButton from "./components/PlusButton";


const BasicTable = (props) => {
    const columns = useMemo(() => COLUMNS, [])
    const data = useMemo(() => DATA, [])
    const defaultColumn = useMemo(() =>DEFAULT_COLUMN , [])
    const filterTypes = { inner_text: INNER_TEXT };

    const {
        getTableProps,
        headerGroups,
        prepareRow,
        selectedFlatRows,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state,
        gotoPage,
        pageCount,
        setPageSize,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: {pageIndex: 0},
            defaultColumn,
            filterTypes,
            globalFilter: "inner_text"
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({getToggleAllRowsSelectedProps}) => (
                        <Checkbox {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({row}) => <Checkbox {...row.getToggleRowSelectedProps()} />
                },
                ...columns
            ])
        }
    );

    const {color} = props;

    const {globalFilter, pageIndex, pageSize} = state
    return (

        <React.Fragment>

            <div
                className={"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " + (color === "light" ? "bg-white" : "bg-blue-900 text-white")}>

                <TableTitle title={"Companies"} color={color} />

                <GlobalFilter filter={globalFilter} SetFilter={setGlobalFilter}/>

                <div className="block w-full overflow-x-auto">
                    <table {...getTableProps()} className="items-center w-full bg-transparent border-collapse">
                        <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                                        (color === "light"
                                            ? "bg-gray-100 text-gray-600 border-gray-200"
                                            : "bg-blue-800 text-blue-300 border-blue-700")

                                        }
                                    >
                                        {column.render("Header")}
                                        <span className="px-3">
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <i className="fas fa-sort-down" aria-hidden="true"/>

                                                : <i className="fas fa-sort-up" aria-hidden="true"/>
                                            : ""}
                                    </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}
                                                className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                                {cell.render("Cell")}
                                            </td>

                                        );
                                    })}
                                </tr>
                            );
                        })}
                        </tbody>
                        {/*<tfoot>*/}
                        {/*{footerGroups.map(group => (*/}
                        {/*    <tr {...group.getFooterGroupProps()}>*/}
                        {/*        {group.headers.map(column => (*/}
                        {/*            <td {...column.getFooterProps()}*/}
                        {/*                className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +*/}
                        {/*                (color === "light"*/}
                        {/*                    ? "bg-gray-100 text-gray-600 border-gray-200"*/}
                        {/*                    : "bg-blue-800 text-blue-300 border-blue-700")}>{column.render("Footer")}</td>*/}
                        {/*        ))}*/}
                        {/*    </tr>*/}
                        {/*))}*/}
                        {/*</tfoot>*/}
                    </table>
                    <PagingButtons
                        gotoPage={gotoPage}
                        canPreviousPage={canPreviousPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        setPageSize={setPageSize}/>
                    <pre>
                        <code>
                          {JSON.stringify(
                              {
                                  selectedFlatRows: selectedFlatRows.map(row => row.original)
                              },
                              null,
                              2
                          )}
                        </code>
                  </pre>
                </div>
            </div>
        </React.Fragment>
    );

}

export default BasicTable;