export const featuresCard = [
    {
        title: "Interviewer Firm",
        icon: {type: "fas fa-filter", color:"bg-red-400"},
        description: "We offer a comprehensive pool of expert interviewers for your company to use and integrate into all stages of your recruitment.",
        display:"lg:pt-12 pt-6"
        // hover: "hover:bg-blue-500 hover:border-transparent hover:shadow-lg hover:text-white"
    },
    {
        title: "Interviewer Marketplace",
        icon: {type: "fas fa-shopping-cart", color:"bg-orange-500"},
        description: "A web platform that experts can use to offer themselves as interviewers for companies, along with our professional interview services.",
        display:""
        // hover: "hover:bg-blue-500 hover:border-transparent hover:shadow-lg hover:text-white"
    },
    {
        title: "Fully Automated Exams",
        icon: {type: "fas fa-diagnoses", color:"bg-teal-500"},
        description: "A web platform that candidates will use for performing fully automated online technical tests that will be graded both by the system and humans.",
        display:"lg:pt-12 pt-6"
    }
    // ,
    // {
    //     title: "AI-Based Interviewing",
    //     icon: {type: "fas fa-brain", color:"bg-purple-500"},
    //     description: "An AI-based interviewing platform that deliver " +
    //         "a human-like experience: context-aware conversations with candidates, adaptive questioning according " +
    //         "to candidate knowledge level.",
    //     display:"lg:pt-12 pt-6"
    // }
    // ,
    // {
    //     title: "Job Characterization",
    //     icon: {type: "fas fa-filter", color:"bg-red-400"},
    //     description: "Defining the capabilities required of a candidate. " +
    //         "From which companies the experience and knowledge is ideal. " +
    //         "And all in light of the development requirements.",
    //     display:"lg:pt-12 pt-6"
    //     // hover: "hover:bg-blue-500 hover:border-transparent hover:shadow-lg hover:text-white"
    // },
    // {
    //     title: "Candidate Management System",
    //     icon: {type: "fas fa-tasks", color:"bg-blue-400"},
    //     description: "Who applied for the position? " +
    //         "What is the stage of each candidate? " +
    //         "Click for schedule a professional interview.",
    //     display:""
    // },
    // {
    //     title: "Boutique Technology Employment agency",
    //     icon: {type: "fas fa-users-slash", color:"bg-green-400"},
    //     description: "Unsuitable CVs have no place in our agency. Let us find the best candidate for your position.",
    //     display:"lg:pt-12 pt-6"
    // }
];