import React, {useEffect, useState} from "react";
import {connect, useSelector} from 'react-redux'
import allActions from "../../actions";

import {profileForm} from "../../components/FormFactory/formConfs";
import FormBuilder from "../../components/FormFactory/FormBuilder";
import {reduxForm} from "redux-form";

let EditProfile = (props)=> {

    const { profile_id } =useSelector( state => state.auth.qualiifly );

    /* use a state to hold the render */
    const [isFirstRender, setFirstRender] = useState(true)


    /* after the first render the user should be logged in (if previously was) */
    useEffect(() => {
        setFirstRender(false)
    }, [])

    useEffect(() => {
        props.fetchProfile()

    }, [ profile_id]);

    const onSubmit = (formValues) => {
        // e.preventDefault()

        console.log("onSubmit", formValues)
        props.createProfile(formValues)
    }

    return (
        <>
            <section className="relative w-full h-full py-40 min-h-screen ">
                <div
                    className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage:
                            "url('https://images.unsplash.com/photo-1505844300428-1ec56dad8de1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80%27')",
                    }}
                ></div>
                <div className="flex content-center items-center flex-wrap">
                    <div className="container mx-auto w-full lg:w-6/12  px-4">
                        <div
                            className="content-center items-center  h-full w-full px-4 relative flex flex-col min-w-0  w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0 rounded-t mb-0 px-6 py-6">
                            <FormBuilder onSubmit={props.handleSubmit(onSubmit)} formConfs={profileForm}/>
                        </div>
                    </div>
                    {/*<div className="w-full lg:w-4/12 px-4">*/}
                    {/*  <CardProfile />*/}
                    {/*</div>*/}
                </div>
            </section>
        </>
    );
}

const validate = formValues => {
    const errors = {};
    if (!formValues.username) {
        errors.username = 'You must enter a username';
    }
    return errors
};

const mapStateToProps = (state, ownProps) => {
    return {initialValues: state.profile.data}
};


EditProfile = reduxForm({
    form: 'editProfileForm',
    enableReinitialize: true,
    destroyOnUnmount: true,
    keepDirtyOnReinitialize: true,
    validate
})(EditProfile)

// You have to connect() to any reducers that you wish to connect to yourself
EditProfile = connect(mapStateToProps, { fetchProfile:allActions.profileActions.fetchProfile, createProfile: allActions.profileActions.createProfile } // bind account loading action creator
)(EditProfile)


export default EditProfile
