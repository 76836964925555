import React from "react";


import FormFieldImage from "../components/FormFieldImage";
import FormFieldMultiSelect from "../components/FormFieldMultiSelect";
import FormField from "../components/FormField";
import FormFieldSelect from "../components/FormFieldSelect";
import FormFieldTextArea from "../components/FormFieldTextArea";

export const mapping = {
    dropzone: FormFieldImage,
    multiselect:FormFieldMultiSelect,
    input:FormField,
    textarea:FormFieldTextArea,
    select:FormFieldSelect,
}


