import React from "react"
import PlusButton from "./PlusButton";

const TableTitle = (props)=>{
    const {title, color} = props
    return(
        <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                    <span className={"font-semibold text-lg " + (color === "light" ? "text-gray-800" : "text-white")}>
                        {title}
                    </span>
                    <PlusButton />

                </div>
            </div>
        </div>
    );
};

export default TableTitle;