import React from "react"

const FormSubmitButton = (props)=>{
    return(
        <div className="rounded-t mb-0 px-6 py-6">
            <div className="text-center justify-between">
                <div className="text-center mt-6">
                {/*<div className=" rounded-t mb-0 px-6 py-6">*/}
                    <button
                        className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit"
                    >
                        {props.Name}
                    </button>
                </div>
            </div>
        </div>

    );

};

export default FormSubmitButton;