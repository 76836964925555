import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import CompanyShow from "./companies/CompanyShow";
import CompanyDelete from "./companies/CompanyDelete";
import CompanyEdit from "./companies/CompanyEdit";
import CompanyCreate from "./companies/CompanyCreate";
import CompanyList from "./companies/CompanyList";


class RecruiterCompany extends React.Component {
    render() {
        return (
            <>
                <Switch>
                    <Route path="/" exact component={CompanyList}/>
                    <Route path="/recruiter/company/new" exact component={CompanyCreate}/>
                    <Route path="/recruiter/company/edit/:id" exact component={CompanyEdit}/>
                    <Route path="/recruiter/company/delete/:id" exact component={CompanyDelete}/>
                    <Route path="/recruiter/company/:id" exact component={CompanyShow}/>
                    {/*<Redirect from="/recruiter/company" to="/recruiter/dashboard"/>*/}
                </Switch>
            </>
        );
    }


}


export default RecruiterCompany;