import React from "react"
import ReactDOM from "react-dom"

const ModalContainer = (props) => {
    return ReactDOM.createPortal(
        <div onClick={props.onDismiss} className="ui details modals visible active">
            <div onClick={e => e.stopPropagation()} className="ui standard modal visible active">
                {props.content}
            </div>

        </div>,
        document.querySelector('#modal-container')
    );
};

export default ModalContainer;