import {SIGN_IN, SIGN_OUT} from "../../actions/types";

const INITIAL_STATE = {
    google: {isSignedIn: null},
    qualiifly: {}
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIGN_IN:
            return {
                ...state,
                google: {...action.payload.google,isSignedIn: true},
                qualiifly:action.payload.qualiifly
            };
        case SIGN_OUT:
            return {...state, google: {isSignedIn: false}, qualiifly: {}};
        default:
            return state;
    }
};