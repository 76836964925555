import React from "react"


const Item = (props)=>{

    const {icon, text} = props;

    return(
        <li className="py-2">
            <div className="flex items-center">
                <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                            <i className={icon}></i>
                          </span>
                </div>
                <div>
                    <h4 className="text-gray-600">
                        {text}
                    </h4>
                </div>
            </div>
        </li>

    );

};

export default Item;
