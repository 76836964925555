import StatusCircle from "../components/StatusCircle";
import ImageAndText from "../components/ImageAndText";
import ImageOnly from "../components/ImageOnly";
import TextOnly from "../components/TextOnly";
import ProgressBar from "../components/ProgressBar";
import TableDropdown from "../../../Dropdowns/TableDropdown";

export const mapping = {
    status_circle: StatusCircle,
    image_and_text:ImageAndText,
    image_only:ImageOnly,
    text_only:TextOnly,
    progress_bar:ProgressBar,
    table_dropdown:TableDropdown,
}
