import _ from "lodash"
import {
    CREATE_COMPANY,
    FETCH_COMPANIES,
    FETCH_COMPANY,
    DELETE_COMPANY,
    EDIT_COMPANY
} from '../../actions/types'

// eslint-disable-next-line
export default (state = {}, action) => {
    switch (action.type) {
        case CREATE_COMPANY:
        case FETCH_COMPANY:
        case EDIT_COMPANY:
            return {...state, [action.payload.id]: action.payload};
        case DELETE_COMPANY:
            return _.omit(state, action.payload);
        case FETCH_COMPANIES:
            return {...state, ..._.mapKeys(action.payload, 'id')}
        default:
            return state


    }
};