import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";

import history from "../history";

// views
import LoginPage from "../views/auth/LoginPage";
import RegisterPage from "../views/auth/RegisterPage";

export default function Auth() {
    return (
        <>
            <Switch>
                <Route history={history} path="/auth/login" exact component={LoginPage}/>
                <Route history={history} path="/auth/register" exact component={RegisterPage}/>
                <Redirect from="/auth" to="/auth/login"/>
            </Switch>

        </>
    );
}
