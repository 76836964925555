import qualiiflyApi from "../apis/qualiiflyApi";
import history from "../history";

import {
    CREATE_COMPANY,
    FETCH_COMPANIES,
    FETCH_COMPANY,
    DELETE_COMPANY,
    EDIT_COMPANY
} from "./types";


export const createCompany = formValues => async (dispatch, getState) => {
    // const {userId} = getState().auth;
    const response = await qualiiflyApi.post('/companies', {...formValues});
    dispatch({type: CREATE_COMPANY, payload: response.data});
    history.push('/');
};

export const fetchCompanies = () => async dispatch => {
    const response = await qualiiflyApi.get('/companies');
    dispatch({type: FETCH_COMPANIES, payload: response.data});
};

export const fetchCompany = id => async dispatch => {
    const response = await qualiiflyApi.get(`/companies/${id}`);
    dispatch({type: FETCH_COMPANY, payload: response.data});
};

export const editCompany = (id, formValues) => async dispatch => {
    const response = await qualiiflyApi.patch(`/companies/${id}`, formValues);
    dispatch({type: EDIT_COMPANY, payload: response.data});
    history.push('/');
};

export const deleteCompany = id => async dispatch => {
    await qualiiflyApi.delete(`/companies/${id}`);
    dispatch({type: DELETE_COMPANY, payload: id});
    history.push('/');
};


// eslint-disable-next-line
export default {
    createCompany,
    fetchCompanies,
    fetchCompany,
    editCompany,
    deleteCompany
}