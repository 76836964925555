import React from "react";
import FormSection from "./components/FormSection";
import FormSubmitButton from "./components/FormSubmitButton";

let FormBuilder = (props) => {

    const renderSections = () => {
        return props.formConfs.data.map(section => (
                <FormSection key={section.sectionName} {...section} updateField={props.updateField}/>
            )
        );
    }

    return (
        <>
            <div
                className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
                <div className="rounded-t bg-white mb-0 px-6 py-6">
                    <h6 className="text-gray-800 text-xl font-bold">{props.formConfs.title}</h6>

                    {/*<div className="text-center flex justify-between">*/}
                    {/*</div>*/}
                </div>
                <form onSubmit={props.onSubmit} >
                    <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                        {renderSections()}
                    </div>
                    <FormSubmitButton Name={props.formConfs.buttonName}/>
                </form>
                {/*<Form onSubmit={this.props.onSubmit} formConfs={this.props.formConfs}/>*/}
            </div>
        </>

    );
}

export default FormBuilder
