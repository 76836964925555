import React from "react"
import Factory from "../factory/Factory";


class FormSection extends React.Component {

    renderSection() {
        const {globalLayout, globalInputType} = this.props

        return this.props.fieldsData.map(field => {
            return (
                <Factory htmlType={globalInputType.htmlType} key={field.name} {...field} globalInputType={globalInputType}
                         globalLayout={globalLayout}  />
            );
        });
    }

    render() {

        const {sectionName} = this.props
        return (
            <React.Fragment>
                <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
                    {sectionName}
                </h6>
                <div className="flex flex-wrap">
                    {this.renderSection()}
                </div>

                <hr className="mt-6 border-b-1 border-gray-400"/>
            </React.Fragment>
        );
    }
}

export default FormSection;