import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CardImage from "../../../components/Cards/CardImage";

export default class ExaminationPlatformHome extends Component {
    render() {
        return (
            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="container mx-auto px-4">
                    <h1 className="text-gray-800 font-semibold text-6xl">
                        Welcome to QUALIIFLY Quizz</h1>
                    <h2 className="text-gray-800 font-semibold text-4xl">
                        CHOOSE YOUR LANGUAGE</h2>
                    <div className="flex flex-wrap">
                        <CardImage title="CSS Quizz" subTitle="Starter" to="/examination-platform/css" img={require("assets/img/demo/face.jpg").default} />
                        {/*<CardImage title="Facial Recognition" subTitle="Face API" to="/demo/camera" img={require("assets/img/demo/face-algorithm.jpg").default} />*/}
                    </div>
                </div>
            </div>
        );
    }
}
