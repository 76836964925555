import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

import {setCookie, getCookie, LOGGEDIN_COOKIE, TOKEN_COOKIE} from "services/cookies.js";
import {tryLogin, loginReset} from "redux/auth/Actions.js";
import Snackbar from "./Snackbar";


class LoginForm extends Component {

    state = {
        cardAnimaton: "cardHidden",
        email: "",
        password: "",
        place: 'tr',
        notificationCount: 0,
        notificationMessage: ''
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }

    handleSubmit = event => {
        const {email, password} = this.state;
        this.props.tryLogin(email, password);
        event.preventDefault();
    }

    // componentDidMount() {
    //   const { history } = this.props;
    //
    //   const loggedIn = getCookie(LOGGEDIN_COOKIE);
    //   console.log(loggedIn)
    //   if (loggedIn === "true") return history.push("/"); //todo create homePAge after login
    //   // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    //   setTimeout(
    //       function () {
    //         this.setState({cardAnimaton: ""});
    //       }.bind(this),
    //       700
    //   );
    // }

    componentDidUpdate(prevProps, prevState) {
        const {history} = this.props;
        const {loginInProgress, loginHasError, loginCompleted, api_token, loggedIn, loginError} = this.props.auth;
        console.log("Enter", loggedIn, loginInProgress, loginHasError, loginCompleted)


        if (!loginInProgress && loginCompleted) {
            // login Completed
            if (loggedIn && !loginHasError) {
                console.log("After login attempt with login successful ")
                if (prevProps.auth !== this.props.auth) {
                    this.setState(prevState => {
                        return {
                            notificationCount: prevState.notificationCount + 1,
                            notificationType: 'success',
                            notificationMessage: {message:'Login Successful'}
                        }
                    })
                }
                setCookie(TOKEN_COOKIE, api_token, {path: "/"});
                setCookie(LOGGEDIN_COOKIE, loggedIn, {path: "/"});
                history.push("/"); //todo create homePAge after login
            } else if (loginHasError) {
                if (prevProps.auth !== this.props.auth) {
                    console.log("After login attempt with error ")
                    this.setState(prevState => {
                        return {
                            notificationCount: prevState.notificationCount + 1,
                            notificationType: 'error',
                            notificationMessage: loginError
                        }
                    })
                }
                this.props.loginReset();
            }
        }
    }

    render() {
        return (
            <>
                <div className="container mx-auto px-4 h-full">
                    <div className="flex content-center items-center justify-center h-full">
                        <div className="w-full lg:w-4/12 px-4">
                            <div
                                className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
                                <div className="rounded-t mb-0 px-6 py-6">
                                    <div className="text-center mb-3">
                                        <h6 className="text-gray-600 text-sm font-bold">
                                            Sign in with
                                        </h6>
                                    </div>
                                    <div className="btn-wrapper text-center">
                                        <button
                                            className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                                            type="button"
                                        >
                                            <img
                                                alt="..."
                                                className="w-5 mr-1"
                                                src={require("assets/img/github.svg").default}
                                            />
                                            Github
                                        </button>
                                        <button
                                            className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                                            type="button"
                                        >
                                            <img
                                                alt="..."
                                                className="w-5 mr-1"
                                                src={require("assets/img/google.svg").default}
                                            />
                                            Google
                                        </button>
                                    </div>
                                    <hr className="mt-6 border-b-1 border-gray-400"/>
                                </div>
                                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                    <div className="text-gray-500 text-center mb-3 font-bold">
                                        <small>Or sign in with credentials</small>
                                    </div>
                                    <form>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                placeholder="Email"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                            />
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Password
                                            </label>
                                            <input
                                                type="password"
                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                placeholder="Password"
                                                name="password"
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div>
                                            <label className="inline-flex items-center cursor-pointer">
                                                <input
                                                    id="customCheckLogin"
                                                    type="checkbox"
                                                    className="form-checkbox text-gray-800 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                />
                                                <span className="ml-2 text-sm font-semibold text-gray-700">
                        Remember me
                      </span>
                                            </label>
                                        </div>

                                        <div className="text-center mt-6">
                                            <button
                                                className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={event => this.handleSubmit(event)}
                                            >
                                                Sign In
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="flex flex-wrap mt-6 relative">
                                <div className="w-1/2">
                                    <a
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        className="text-gray-300"
                                    >
                                        <small>Forgot password?</small>
                                    </a>
                                </div>
                                <div className="w-1/2 text-right">
                                    <Link to="/auth/register" className="text-gray-300">
                                        <small>Create new account</small>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Snackbar notificationCount={this.state.notificationCount}
                          notificationType={this.state.notificationType}
                          notificationMessage={this.state.notificationMessage}/>
            </>
        );
    }
}


const bindAction = (dispatch) => {
    return {
        tryLogin: (email, password) => dispatch(tryLogin(email, password)),
        loginReset: () => dispatch(loginReset())
    };
}

const mapStateToProps = state => ({
    auth: state.auth
});


export default connect(mapStateToProps, bindAction)(LoginForm);
