import React from 'react';

// App css
import './styles/app.css';
import Main from "./components/Main";

const QuizCSS = ()=>{
    return (
        <Main />
    );
}

export default QuizCSS;