import React, {Component} from "react";

import CardProfile from "../../components/Cards/CardProfile";
import CardSettings from "../../components/Cards/CardSettings";




class UserProfile extends Component {
    render() {
        // const {profile} = this.state;
        //
        //
        // const fullName =  'fullName'  //`${props.qualiifly.given_name} ${props.qualiifly.family_name}`
        // const location = 'location' //`${props.profile.address} ${props.profile.city}, ${props.profile.country} ${props.profile.postal_code}`;
        // const title = 'title' //props.profile.title;
        // const eduction = 'eduction' //`${props.profile.degree} ${props.profile.field_of_study}, ${props.profile.school}`
        // const about_me = 'about_me' //props.profile.about_me

        return (
            <>
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-8/12 px-4">
                        <CardSettings />
                    </div>
                    <div className="w-full lg:w-4/12 px-4">
                        <CardProfile />
                    </div>
                </div>
            </>
        );
    }
}


export default UserProfile;