import React from "react"
import {Field, reduxForm} from 'redux-form'
import firebase from "firebase/app"
import "firebase/database"

class SubmitForm extends React.Component {

    state = {messageSent: false}

    writeUserData = (formValues) => {
        const {fullName, email, message} = formValues
        var today = new Date();
        var id = today.toISOString().split('T')[0] + "-" + email.replace(/@/g, ' at ').replace(/[^a-z0-9-]+/gi, '-')
            .replace(/^-|-$/g, '');
        firebase.database().ref('connections/' + id).set({
            fullName: fullName,
            email: email,
            message: message,
            emailSent: "No"
        });
    }
    onSubmit = (formValues) => {
        console.log(formValues)
        this.writeUserData(formValues)
        // Your message has been sent.
        this.props.reset()
        this.setState({messageSent: true})
    }

    componentDidMount() {

        if (!firebase.apps.length) {
            const firebaseConfig = {
                databaseURL: "https://qualiifly-e1fe5.firebaseio.com",
                apiKey: "AIzaSyBxoLfKaXAUg67z9O7KyeLj6OhyGfBxvAk"
            };
            firebase.initializeApp(firebaseConfig);
        } else {
            firebase.app(); // if already initialized, use that one
        }

    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div id="contact-us" className="container mx-auto px-4">
                    <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
                        <div className="w-full lg:w-6/12 px-4">
                            <div
                                className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                                <div className="flex-auto p-5 lg:p-10">
                                    <h4 className="text-2xl font-semibold">
                                        Want to work with us?
                                    </h4>
                                    <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                                        Complete this form and we will get back to you in 24
                                        hours.
                                    </p>
                                    <div className="relative w-full mb-3 mt-8">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="fullName"
                                        >
                                            Full Name
                                        </label>
                                        <Field
                                            name="fullName"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            component="input"
                                            type="text"
                                            placeholder="Full Name"
                                        />
                                    </div>

                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="email"
                                        >
                                            Email
                                        </label>
                                        <Field
                                            name="email"
                                            component="input"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            type="email"
                                            placeholder="Email"
                                        />
                                    </div>

                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="message"
                                        >
                                            Message
                                        </label>
                                        <Field name="message"
                                               component="textarea"
                                               rows="4"
                                               cols="80"
                                               className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                               placeholder="Type a message..."
                                        />
                                    </div>
                                    <div className="text-center mt-6">
                                        <button
                                            className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            // type="button"
                                            type="submit"
                                        >
                                            Send Message
                                        </button>
                                    </div>
                                    {
                                        this.state.messageSent ?
                                            <p className="leading-relaxed mt-1 mb-4 text-green-500">
                                                Your message has been sent.
                                            </p> : null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }


}


export default reduxForm({
    form: 'connections' // a unique identifier for this form
})(SubmitForm)