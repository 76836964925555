import React, {Component} from "react";

// Profile staff
import {
    tryGetProfile,
    profileReset,
    tryChangePassword,
    changePasswordReset,
    tryUpdateProfile,
    updateProfileReset,
} from "redux/user/Actions.js";

import {connect} from "react-redux";


class CardSettings extends Component {
    // components
    state = {
        profile: {
            first_name: "",
            last_name: "",
            address: "",
            city: "",
            country: "",
            postal_code: "",
            about_me: "",
            title: "",
            school:""
        },
        username: "",
        email: "",
        password: "",

    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => {
            return {
                profile: {...prevState.profile, [name]: value}
            }
        })

        // this.setState({[name]: value});
    }

    componentDidMount() {
        console.log("componentDidMount")
        this.props.tryGetProfile();
    }

    nullToEmpty(value) {
        return (value === null) ? "" : value
    }

    objectMap = (object, mapFn) => {
        return Object.keys(object).reduce(function (result, key) {
            result[key] = mapFn(object[key])
            return result
        }, {})
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("componentDidUpdate")
        // handle profile
        const {getProfileInProgress, getProfileHasError, getProfileCompleted, user} = this.props.user;

        if (!getProfileInProgress && !getProfileHasError && getProfileCompleted) {
            const {profile} = user;
            this.setState({
                username: user.username,
                email: user.email,
                profile: this.objectMap(profile, this.nullToEmpty)
                //
                // first_name: profile.first_name,
                // last_name: profile.last_name,
                // address: profile.address ? profile.address : '',
                // city: profile.city,
                // country: profile.country,
                // postal_code: profile.postal_code ? profile.postal_code : '',
                // about_me: profile.about_me,
                // title: profile.about_me
            });

            this.props.profileReset();
        }
    }

    handleUpdateProfile = event => {
        let {profile} = this.state;
        this.props.tryUpdateProfile(profile);
        this.setState({updateProfile: true});
        event.preventDefault();
    }

    render() {
        return (
            <>
                <div
                    className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
                    <div className="rounded-t bg-white mb-0 px-6 py-6">
                        <div className="text-center flex justify-between">
                            <h6 className="text-gray-800 text-xl font-bold">My account</h6>
                            <button
                                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={event => this.handleUpdateProfile(event)}
                            >
                                Update
                            </button>
                        </div>
                    </div>
                    <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                        <form>
                            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
                                User Information
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Username
                                        </label>
                                        <input
                                            type="text"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            name="username"
                                            value={this.state.username}
                                            onChange={this.handleChange}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Email address
                                        </label>
                                        <input
                                            type="email"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            name="first_name"
                                            value={this.state.profile.first_name}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            name="last_name"
                                            value={this.state.profile.last_name}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <hr className="mt-6 border-b-1 border-gray-400"/>

                            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
                                Contact Information
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Address
                                        </label>
                                        <input
                                            type="text"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            name="address"
                                            value={this.state.profile.address}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            City
                                        </label>
                                        <input
                                            type="text"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            name="city"
                                            value={this.state.profile.city}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Country
                                        </label>
                                        <input
                                            type="text"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            name="country"
                                            value={this.state.profile.country}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Postal Code
                                        </label>
                                        <input
                                            type="text"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            name="postal_code"
                                            value={this.state.profile.postal_code}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <hr className="mt-6 border-b-1 border-gray-400"/>

                            <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
                                About Me
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Title
                                        </label>
                                        <input
                                            type="text"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            name="title"
                                            value={this.state.profile.title}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Eduction
                                        </label>
                                        <input
                                            type="text"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            name="school"
                                            value={this.state.profile.school}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            About me
                                        </label>
                                        <textarea
                                            type="text"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            rows="4"
                                            name="about_me"
                                            value={this.state.profile.about_me}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    }
}

const bindAction = dispatch => {
    return {
        tryGetProfile: () => dispatch(tryGetProfile()),
        profileReset: () => dispatch(profileReset()),
        tryChangePassword: (password) => dispatch(tryChangePassword(password)),
        changePasswordReset: () => dispatch(changePasswordReset()),
        tryUpdateProfile: (profile) => dispatch(tryUpdateProfile(profile)),
        updateProfileReset: () => dispatch(updateProfileReset()),
    };
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, bindAction)(CardSettings);
