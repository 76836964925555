import React from "react";

const StatusEnum = {
    COMPLETED: {name: "completed", color: "text-green-500"},
    ON_SCHEDULE: {name: "on schedule", color: "text-teal-500"},
    PENDING: {name: "pending", color: "text-orange-500"},
    DELAYED: {name: "delayed", color: "text-red-500"},
}

const StatusCircle = (props) => {
    const {name, color} = StatusEnum[props.text.toUpperCase()]
    return (
        <div>
            <i className={`fas fa-circle ${color} mr-2`}/>
            {name}
        </div>
    );
};

export default StatusCircle;