import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

import history from "../history";

// layouts

import User from "layouts/User";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/landing/Landing.js";
import Index from "views/Index.js";
import Navbar from "./Navbars/AuthNavbar";
import InterviewerMarketplace from "../views/InterviewerMarketplace";
import Recruiter from "../layouts/Recruiter";
import Privacy from "../views/legal/Privacy";
import Demo from "../layouts/Demo";
import ExaminationPlatform from "../layouts/ExaminationPlatform";

const App = () => {
    return (
        <Router history={history}>
            <Switch>
                {/* add routes with layouts */}
                <Route path="/demo" component={Demo}/>
                <Route path="/examination-platform" component={ExaminationPlatform}/>
                <Route path="/recruiter" component={Recruiter}/>
                <Route path="/user" component={User}/>
                <Route path="/auth" component={Auth}/>
                {/* add routes without layouts */}
                <Route path="/landing" exact component={Landing}/>
                <Route path="/privacy" exact component={Privacy}/>
                <Route path="/interviewer-marketplace" exact component={InterviewerMarketplace}/>

                {/*<Route path="/edit-profile" exact component={editProfile}/>*/}
                {/*<Route path="/wiz" exact component={()=><WizardForm onSubmit={showResults} />}/>*/}
                <Route path="/" exact component={Index}/>
                {/* add redirect for first page */}
                <Redirect from="*" to="/"/>
            </Switch>
        </Router>
    );
}

export default App;
