import React from "react";
import PropTypes from "prop-types";
import BasicTable from "./BasicTable";

// components

const conf = {
  title: "Candidates",
  header:["Project", "Budgets", "Status", "Users", "Completion", "More"],
  rows: [
    [
      {type: "image_and_text", imageSrc: "/assets/img/team-1-800x800.jpg", text: "Adam Levi"},
      {type: "text_only", text: "$2,500 USD"},
      {type: "status_circle", text: "completed"},
      {type: "image_only", imageSrc: "/assets/img/team-1-800x800.jpg"},
      {type: "progress_bar", percentage: 100},
      {type: "table_dropdown"}
    ],  [
      {type: "image_and_text", imageSrc: "/assets/img/team-1-800x800.jpg", text: "Adam Levi"},
      {type: "text_only", text: "$2,500 USD"},
      {type: "status_circle", text: "completed"},
      {type: "image_only", imageSrc: "/assets/img/team-1-800x800.jpg"},
      {type: "progress_bar", percentage: 100},
      {type: "table_dropdown"}
    ],  [
      {type: "image_and_text", imageSrc: "/assets/img/team-1-800x800.jpg", text: "Adam Levi"},
      {type: "text_only", text: "$2,500 USD"},
      {type: "status_circle", text: "completed"},
      {type: "image_only", imageSrc: "/assets/img/team-1-800x800.jpg"},
      {type: "progress_bar", percentage: 100},
      {type: "table_dropdown"}
    ],  [
      {type: "image_and_text", imageSrc: "/assets/img/team-1-800x800.jpg", text: "Adam Levi"},
      {type: "text_only", text: "$2,500 USD"},
      {type: "status_circle", text: "delayed"},
      {type: "image_only", imageSrc: "/assets/img/team-1-800x800.jpg"},
      {type: "progress_bar", percentage: 20},
      {type: "table_dropdown"}
    ],
  ]
}


export default function CardTable({ color }) {
  return (
      <React.Fragment>
      <BasicTable color={color}/>
      </React.Fragment>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
