import React, {Component} from 'react';
// import {withRouter} from 'react-router-dom';
import Webcam from 'react-webcam';
import {loadModels, getFullFaceDescription, createMatcher} from '../api/face';
import * as faceapi from "face-api.js";
import ImageCard from "../components/ImageCard";
import {Link} from "react-router-dom";

// Import face profile
const JSON_PROFILE = require('../descriptors/bnk48.json');

const WIDTH = 420;
const HEIGHT = 420;
const inputSize = 160;

class VideoInput extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.webcam = React.createRef();
        this.state = {
            fullDesc: null,
            detections: null,
            descriptors: null,
            faceMatcher: null,
            match: null,
            facingMode: 'user',
            croppedFaces: null,
            screenshot: null
        };
    }

    componentDidMount = async () => {
        this._isMounted = true;
        await loadModels();
        if (this._isMounted) {
            this.setState({faceMatcher: await createMatcher(JSON_PROFILE)});
        }
        this.setInputDevice();
    };

    setInputDevice = () => {
        this.startCapture();
    };

    startCapture = () => {
        this.interval = setInterval(() => {
            this.capture();
        }, 500);
    };

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.interval);
    }

    capture = async () => {
        if (!!this.webcam.current) {
            const screenshot = this.webcam.current.getScreenshot();
            // fetch image to api

            await getFullFaceDescription(
                screenshot,
                inputSize
            ).then(async data => {
                const {fullDesc, img} = data;
                if (!!fullDesc) {
                    const drawBox = await this.renderBox(fullDesc);

                    // console.log(fullDesc);
                    this.setState({
                        detections: fullDesc.map(fd => fd.detection),
                        descriptors: fullDesc.map(fd => fd.descriptor),
                        fullDesc: fullDesc,
                        screenshot: img,
                        drawBox
                        // alignedRect: fullDesc.map(fd => fd.alignedRect),
                        // expressions: fullDesc.map(fd => fd.expressions),
                        // gender: fullDesc.map(fd => fd.gender),
                        // landmarks: fullDesc.map(fd => fd.landmarks)
                    });
                    // console.log(this.state)
                }
            });

            if (!!this.state.descriptors && !!this.state.faceMatcher) {
                let match = await this.state.descriptors.map(descriptor =>
                    this.state.faceMatcher.findBestMatch(descriptor)
                );
                this.setState({match});
            }
        }
    };

    async renderBox(fullDesc) {
        let drawBox = null;
        const match = true;
        if (!!fullDesc) {
            drawBox = await Promise.all(fullDesc.map(async (full, i) => {
                // console.log(full)
                const age = Math.floor(full.age);
                const alignedRect = full.alignedRect;
                const expressions = full.expressions.asSortedArray();
                var expressionsDisplay = `${expressions[0].expression}(${expressions[0].probability.toFixed(2)})`
                if (expressions[1].probability >= 0.05) {
                    expressionsDisplay = `${expressionsDisplay}, ${expressions[1].expression}(${expressions[1].probability.toFixed(2)})`
                }
                // console.log(expressions)

                const gender = full.gender;
                const landmarks = full.landmarks;
                const detection = full.detection
                // console.log(detection)
                let _H = detection.box.height;
                let _W = detection.box.width;
                let _X = detection.box._x;
                let _Y = detection.box._y;


                if (this.state.screenshot != null){
                    const regionsToExtract = [
                        new faceapi.Rect(_X, _Y, _W, _H)
                    ]
                    const canvases = await faceapi.extractFaces(this.state.screenshot, regionsToExtract)
                    // console.log(canvases)
                    this.setState({croppedFaces:canvases[0]})
                }


                return (
                    <div key={i}>
                        <div
                            style={{
                                position: 'absolute',
                                border: 'solid',
                                borderColor: 'blue',
                                height: _H,
                                width: _W,
                                transform: `translate(${_X}px,${_Y}px)`
                            }}
                        >
                            {!!match ? (
                                <p
                                    style={{
                                        // backgroundColor: 'blue',
                                        // border: 'solid',
                                        // borderColor: 'blue',
                                        width: _W,
                                        marginTop: 0,
                                        color: '#fff',
                                        transform: `translate(-3px,${_H}px)`
                                    }}
                                >
                                    {/*{match[i]._label}*/}
                                    {"Unknown"}
                                    <br/>
                                    {`${gender},  ${age} years`}
                                    <br/>
                                    {expressionsDisplay}
                                </p>

                            ) : null}
                        </div>
                    </div>
                );
            }));
        }

        return drawBox;
    }

    render() {
        const {fullDesc, match, facingMode} = this.state;

        let videoConstraints = null;
        let camera = '';
        if (!!facingMode) {
            videoConstraints = {
                width: WIDTH,
                height: HEIGHT,
                facingMode: facingMode
            };
            if (facingMode === 'user') {
                camera = 'Front';
            } else {
                camera = 'Back';
            }
        }


        return (
            <div>
                <Link to="/demo"
                      className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                >
                    <small>Back to demo home page</small>
                </Link>
                <div
                    className="Camera"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <p>Camera: {camera}</p>
                    <div
                        style={{
                            width: WIDTH,
                            height: HEIGHT
                        }}
                    >
                        <div style={{position: 'relative', width: WIDTH}}>
                            {!!videoConstraints ? (
                                <div style={{position: 'absolute'}}>
                                    <Webcam
                                        audio={false}
                                        width={WIDTH}
                                        height={HEIGHT}
                                        ref={this.webcam}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={videoConstraints}
                                    />
                                </div>
                            ) : null}
                            {!!this.state.drawBox ? this.state.drawBox : null}
                        </div>
                    </div>
                </div>
                <ImageCard imgSrc={this.state.croppedFaces} des description="Screen Shoot"/>
            </div>
        );
    }
}

export default VideoInput;
