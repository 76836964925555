/*eslint-disable*/
import React from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import Landing from "./landing/Landing";
import Navbar from "../components/Navbars/AuthNavbar";
import {Router} from "react-router-dom";

export default function Index() {
  return (
    <>
        <Navbar transparent />

        <Landing />
    </>
  );
}
