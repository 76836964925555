import React from "react"


class CardInterviewer extends React.Component{

  render(){
      return (
          <div>
              <div
                  className="group cursor-pointer rounded-lg p-4 border border-gray-200 hover:bg-blue-500 hover:border-transparent hover:shadow-lg">
                  <dl className="grid sm:block lg:grid xl:block grid-cols-2 grid-rows-2 items-center">
                      <div className="mt-6">
                          <button
                              className="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                              type="button"
                          >
                              <i className="fas fa-hands-helping"></i>
                          </button>
                      </div>

                      <img
                          alt="..."
                          src={require("assets/img/team-4-470x470.png").default}
                          className="shadow-lg rounded-full mx-auto max-w-120-px"
                      />
                      <div>
                          <dt className="sr-only">Title</dt>
                          <dd className="leading-6 font-medium text-black group-hover:text-blue-600">API Integration</dd>
                      </div>
                      <div>
                          <dt className="sr-only">Category</dt>
                          <dd className="text-sm font-medium group-hover:text-light-blue-200 sm:mb-4 lg:mb-0 xl:mb-4">Engineering</dd>
                      </div>
                      <div className="mt-6">
                          <button
                              className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                              type="button"
                          >
                              <i className="fab fa-dribbble"></i>
                          </button>
                          <button
                              className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                              type="button"
                          >
                              <i className="fab fa-google"></i>
                          </button>
                          <button
                              className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                              type="button"
                          >
                              <i className="fab fa-twitter"></i>
                          </button>
                          <button
                              className="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                              type="button"
                          >
                              <i className="fab fa-instagram"></i>
                          </button>
                      </div>

                      {/*<div className="col-start-2 row-start-1 row-end-3">*/}
                      {/*    <dt className="sr-only">Users</dt>*/}
                      {/*    <dd className="flex justify-end sm:justify-start lg:justify-end xl:justify-start -space-x-2">*/}
                      {/*        <img*/}
                      {/*            src="https://images.unsplash.com/photo-1531123897727-8f129e1688ce?auto=format&amp;fit=facearea&amp;facepad=2&amp;w=48&amp;h=48&amp;q=80"*/}
                      {/*            alt="" width="48" height="48"*/}
                      {/*            className="w-7 h-7 rounded-full bg-gray-100 border-2 border-white" loading="lazy" />*/}
                      {/*    </dd>*/}
                      {/*</div>*/}
                  </dl>
              </div>

          </div>
      );
  }
}

export default CardInterviewer;