import React from "react"
import {Link} from "react-router-dom";

// todo - refactor similar to page element
const SelectedLinkElement = (props)=>{
    const {to, displayName, icon} = props
    return (
        <Link
            className={
                "text-xs uppercase py-3 font-bold block " +
                (window.location.href.indexOf(to) !== -1
                    ? "text-blue-500 hover:text-blue-600"
                    : "text-gray-800 hover:text-gray-600")
            }
            to={to}
        >
            <i
                className={
                    `${icon} mr-2 text-sm ` +
                    (window.location.href.indexOf(to) !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                }
            ></i>{" "}
            {displayName}
        </Link>
    );
};


export default SelectedLinkElement;