import React from "react"

// components
const FACES_JSON = "FACES_JSON";

class ImageCard extends React.Component {


    constructor(props) {
        super(props);
        this.imageRef = React.createRef();
        this.state = {spans: 0, fullName: ''}
    }

    componentDidMount() {
        // this.imageRef.current.addEventListener('load', this.setSpans)

    }

    setSpans = () => {
        const height = this.imageRef.current.clientHeight
        const spans = Math.ceil(height / 10) + 1
        // equal to {spans: spans}
        this.setState({spans})
    }

    handleChange = event=> {
        this.setState({fullName: event.target.value});
    }

    handleSubmit = event=> {
        event.preventDefault();
        const fullName = this.state.fullName;
        if (fullName){
            let facesJson = localStorage.getItem(FACES_JSON);
            if (facesJson) {
                const fullNameToken = `NAME_${fullName}`
                // console.log("------------------token",token)
                // return { Authorization: 'Bearer ' + token };
            } else {
                facesJson = {fullName:{name:fullName, "descriptors": [this.props.description]} }
                localStorage.setItem(FACES_JSON, facesJson);

                return {};
            }
            alert('A name was submitted: ' + fullName);

        }


    }

    render() {
        const {description, imgSrc} = this.props
        if (imgSrc != null) {
            // var image = new Image();
            // image.id = "pic";
            // image.src = imgSrc.toDataURL('image/jpeg', 1.0)
            const imageBase64 = imgSrc.toDataURL('image/jpeg', 1.0)
            return (
                <div style={{paddingTop: "3.5rem", paddingBottom: "3.5rem"}}>
                    <img ref={this.imageRef} alt={description}
                         src={imageBase64}/>
                    <div>
                        <form onSubmit={this.handleSubmit}>
                            <input type="text" placeholder="Full Name" value={this.state.value} onChange={this.handleChange} />
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            );
        } else {
            return null
        }

    }
}


export default ImageCard