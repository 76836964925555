import React from "react";

const GlobalFilter = ({filter, SetFilter}) => {
    return (
        <span>
            <input
                className="focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10"
                value={filter || ''}
                onChange={e => SetFilter(e.target.value || undefined)}
                placeholder={`Search All ...`}
            />
        </span>
    );
};

export default GlobalFilter;