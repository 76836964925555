import React from "react"
import {Link} from "react-router-dom";


class CardImage extends React.Component{

  render(){
     const {title, subTitle, to, img} = this.props
      return (
          <div>

              <div
                  className="group cursor-pointer rounded-lg p-4 border border-gray-200 hover:bg-blue-500 hover:border-transparent hover:shadow-lg">
                  <Link to={to}
                  >
                  <dl className="grid sm:block lg:grid xl:block grid-cols-2 grid-rows-2 items-center">
                      <img
                          alt="..."
                          src={img}
                          className="shadow-lg rounded-full mx-auto max-w-320-px"
                      />
                      <div>
                          <dt className="sr-only">Title</dt>
                          <dd className="leading-6 font-medium text-black group-hover:text-blue-600">{title}</dd>
                      </div>
                      <div>
                          <dt className="sr-only">Category</dt>
                          <dd className="text-sm font-medium group-hover:text-light-blue-200 sm:mb-4 lg:mb-0 xl:mb-4">{subTitle}</dd>
                      </div>


                  </dl>
                  </Link>

              </div>

          </div>
      );
  }
}

export default CardImage;