import React from 'react';
import FormField from "./FormField";
import {Field} from "redux-form";

class FormFieldSelect extends FormField {

    // onChange = function(event, newValue, previousValue, name) {
    //     this.props.dispatch(changeFieldValue('myFormName', 'anotherField', 'value'));
    // }
    renderSelect(options){
        return options.map((option, index) => {
            return (
                <option key={index} value={index}>{option}</option>
            )
        });
    }
    renderInput = ({input, label, meta, type, readonly, values}) => {
        // const className = `${classNameInput} ${meta.error && meta.touched? 'error': ''}`;
        return (

            <div className="relative w-full mb-3">
                <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                >
                    {label}
                </label>
                <select  {...input}>
                    {this.renderSelect(values)}
                </select>

                {this.renderError(meta)}
            </div>
        );

    }
    render() {
        const {globalLayout, name, layout, type, values} = this.props;
        const layoutToUse = (layout ? layout : globalLayout)
        return (
            <div className={`w-full lg:w-${layoutToUse} px-4`}>
                <Field name={name}
                       label={this.convertUnderScoreSentenceCase(name)}
                       component={this.renderInput}
                       type={type}
                       values={values}
                />
            </div>
        );
    }

}

export default FormFieldSelect;