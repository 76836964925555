import React, {Component} from "react";
import {getCookie, TOKEN_COOKIE} from "../../services/cookies";

// Profile staff
import {
    tryGetProfile,
    profileReset,
} from "redux/user/Actions.js";

import {connect} from "react-redux";

//show more
import ShowMoreText from 'react-show-more-text';


// Import React FilePond
import {FilePond, registerPlugin} from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

class CardProfile extends Component {

    state = {
        user: {},
        files: []
    }

    componentDidMount() {
        console.log("componentDidMount")
        this.props.tryGetProfile();
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("componentDidUpdate")
        // handle profile
        const {getProfileInProgress, getProfileHasError, getProfileCompleted, user} = this.props.user;

        if (!getProfileInProgress && !getProfileHasError && getProfileCompleted) {
            const newState = {
                user: user
            }

            if (user.profile.avatar) {
                console.log(user.profile.avatar)
                newState.files = [{
                    source: user.profile.avatar,
                    options: {
                        type: 'local'
                    }
                }]
            }
            this.setState(newState);

            this.props.profileReset();
        }
    }

    executeOnShowClick(isExpanded) {
        console.log(isExpanded);
    }

    handleInit() {
        console.log("FilePond instance has initialised", this.pond);
    }

    render() {
        const {profile} =  this.state.user
        if(profile == null) {
            return null
        }
        const fullName =  `${profile.first_name} ${profile.last_name}`
        const location = `${profile.country}, ${profile.city}, `;
        const title = profile.title;
        const eduction = `${profile.field_of_study}, ${profile.school}`
        const about_me = profile.about_me
        return (
            <>
                <div
                    className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
                    <div className="px-6">
                        <div className="flex flex-wrap justify-center">
                            <div className="w-full px-4 flex justify-center">
                                <div className="relative">
                                    <FilePond
                                        className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                                        ref={ref => (this.pond = ref)}
                                        files={this.state.files}
                                        allowMultiple={false}
                                        allowReorder={true}
                                        maxFiles={1}
                                        server={{
                                            url: process.env.REACT_APP_API_URL,
                                            process: {
                                                url: './api/user/profile/upload_image/',
                                                method: 'POST',
                                                headers: {
                                                    Authorization: 'Bearer ' + getCookie(TOKEN_COOKIE)
                                                },
                                                timeout: 7000,
                                                onload: null,
                                                onerror: null
                                            },
                                            load: async (source, load, error, progress, abort, headers) => {
                                                const request = new XMLHttpRequest();
                                                request.open('GET', source, true);
                                                // request.setRequestHeader('Authorization', await getTokenSilently());
                                                request.responseType = 'blob';

                                                request.onload = function () {
                                                    if (request.status >= 200 && request.status < 300) {
                                                        load(request.response);
                                                    } else {
                                                        // Can call the error method if something is wrong, should exit after
                                                        error('oh no');
                                                    }
                                                };

                                                request.send();
                                            }
                                        }
                                        }
                                        name="profile_image"
                                        oninit={() => this.handleInit()}
                                        onupdatefiles={fileItems => {
                                            // Set currently active file objects to this.state
                                            this.setState({
                                                files: fileItems.map(fileItem => fileItem.file)
                                            });
                                        }}
                                        // imagePreviewMaxHeight={20}
                                        // imageCropAspectRatio={'1:1'}
                                        // imageResizeTargetWidth={200}
                                        // imageResizeTargetHeight={200}
                                        stylePanelLayout={'compact circle'}
                                        styleLoadIndicatorPosition={'center bottom'}
                                        styleProgressIndicatorPosition={'right bottom'}
                                        styleButtonRemoveItemPosition={'left bottom'}
                                        styleButtonProcessItemPosition={'right bottom'}
                                        instantUpload={false}
                                    />
                                </div>
                            </div>
                            <div className="w-full px-4 text-center mt-20">
                                <div className="flex justify-center py-4 lg:pt-4 pt-8">
                                    <div className="mr-4 p-3 text-center">
                  <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                    22
                  </span>
                                        <span className="text-sm text-gray-500">Friends</span>
                                    </div>
                                    <div className="mr-4 p-3 text-center">
                  <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                    10
                  </span>
                                        <span className="text-sm text-gray-500">Photos</span>
                                    </div>
                                    <div className="lg:mr-4 p-3 text-center">
                  <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                    89
                  </span>
                                        <span className="text-sm text-gray-500">Comments</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-12">
                            <h3 className="text-xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
                                {fullName}
                            </h3>
                            <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                                <i className="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>{" "}
                                {location}
                            </div>
                            <div className="mb-2 text-gray-700 mt-10">
                                <i className="fas fa-briefcase mr-2 text-lg text-gray-500"></i>
                                {title}
                            </div>
                            <div className="mb-2 text-gray-700">
                                <i className="fas fa-university mr-2 text-lg text-gray-500"></i>
                                {eduction}
                            </div>
                        </div>
                        <div className="mt-10 py-10 border-t border-gray-300 text-center">
                            <div className="flex flex-wrap justify-center">
                                <div className="w-full lg:w-9/12 px-4">
                                    <ShowMoreText
                                        /* Default options */
                                        lines={3}
                                        more={<p>Show more</p>}
                                        less={<p>Show less</p>}
                                        className='content-css mb-4 text-lg leading-relaxed text-gray-800'
                                        anchorClass='my-anchor-css-class font-normal text-blue-500'
                                        onClick={this.executeOnShowClick}
                                        expanded={false}
                                        width={280}
                                        // keepNewLines = {true}
                                    >
                                        {about_me}
                                    </ShowMoreText>
                                    {/*<p className="mb-4 text-lg leading-relaxed text-gray-800">*/}
                                    {/*    {about_me}*/}
                                    {/*</p>*/}
                                    {/*<a*/}
                                    {/*    href="#pablo"*/}
                                    {/*    className="font-normal text-blue-500"*/}
                                    {/*    onClick={(e) => e.preventDefault()}*/}
                                    {/*>*/}
                                    {/*    Show more*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }


}

const bindAction = dispatch => {
    return {
        tryGetProfile: () => dispatch(tryGetProfile()),
        profileReset: () => dispatch(profileReset()),
    };
}

const mapStateToProps = state => ({
    user: state.user
});


export default connect(mapStateToProps, bindAction)(CardProfile);
