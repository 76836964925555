import React from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom";
import CompanyCard from "./CompanyCard";
import allActions from "../../../actions";

class CompanyList extends React.Component {

    componentDidMount() {
        this.props.fetchCompanies()
    }

    renderList() {
        return this.props.companies.map(company => {
            return (
                <CompanyCard company={company} isAdmin={company.userId === this.props.currentUserId}/>
            )
        });
    }

    renderCreate() {
        if (this.props.isSignedIn) {
            return (
                <div style={{textAlign: 'right'}}>
                    <Link to="/companies/new" className="ui button primary">
                        Create Company
                    </Link>
                </div>
            );
        }

    }

    render() {
        return (
            <div>
                <h2>companies</h2>
                <div className="ui celled list">
                    {this.renderList()}
                </div>
                {this.renderCreate()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        companies: Object.values(state.companies),
        currentUserId: state.auth.userId,
        isSignedIn: state.auth.isSignedIn
    }
};

export default connect(mapStateToProps, {fetchCompanies:allActions.companyActions.fetchCompanies})(CompanyList);