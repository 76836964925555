import React from "react";
import Factory from "./factory/Factory";

export const INNER_TEXT = (rows, id, filterValue) => {
    return rows.filter(row => {
        return id.some(inner_id => {
            const rowValue = row.values[inner_id];
            const textVal = rowValue !== undefined ? rowValue.text : undefined

            return textVal !== undefined
                ? String(textVal)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : false;
        })
    });
}


export const DEFAULT_COLUMN = {
    Cell: ({cell: {value}}) => <Factory {...value}  />,
    sortMethod: (a, b) => {
        return a.text.localeCompare(b.teal);
    }
}


export const COLUMNS =
    [
        {
            Header: "Project",
            Footer: "Project",
            accessor: "project",
        },
        {
            Header: "Budgets",
            Footer: "Budgets",
            accessor: "budgets",
        },
        {
            Header: "Status",
            Footer: "Status",
            accessor: "status",
        },
        {
            Header: "Users",
            Footer: "Users",
            accessor: "users",
            disableSortBy: true,
        },
        {
            Header: "Completion",
            Footer: "Completion",
            accessor: "completion",
            sortMethod: (a, b) => {
                return Number(a.percentage) - Number(b.percentage);
            }
        },
        {
            Header: "More",
            Footer: "More",
            accessor: "more",
            disableSortBy: true,
        }
    ];


export const DATA = [
    {
        project: {type: "image_and_text", imageSrc: "/assets/img/team-1-800x800.jpg", text: "Adam Levi"},
        budgets: {type: "text_only", text: "$2,500 USD"},
        status: {type: "status_circle", text: "completed"},
        users: {type: "image_only", imageSrc: "/assets/img/team-1-800x800.jpg"},
        completion: {type: "progress_bar", percentage: 100},
        more: {type: "table_dropdown"}
    },
    {
        project: {type: "image_and_text", imageSrc: "/assets/img/team-1-800x800.jpg", text: "bdam Levi"},
        budgets: {type: "text_only", text: "$1,500 USD"},
        status: {type: "status_circle", text: "delayed"},
        users: {type: "image_only", imageSrc: "/assets/img/team-1-800x800.jpg"},
        completion: {type: "progress_bar", percentage: 50},
        more: {type: "table_dropdown"}
    },
    {
        project: {type: "image_and_text", imageSrc: "/assets/img/team-1-800x800.jpg", text: "cdam Levi"},
        budgets: {type: "text_only", text: "$500 USD"},
        status: {type: "status_circle", text: "pending"},
        users: {type: "image_only", imageSrc: "/assets/img/team-1-800x800.jpg"},
        completion: {type: "progress_bar", percentage: 25},
        more: {type: "table_dropdown"}
    }
];