export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILURE = "GET_PROJECTS_FAILURE";
export const GET_PROJECTS_RESET = "GET_PROJECTS_RESET";

export const GET_RECOMMENDED_PROJECTS_REQUEST = "GET_RECOMMENDED_PROJECTS_REQUEST";
export const GET_RECOMMENDED_PROJECTS_SUCCESS = "GET_RECOMMENDED_PROJECTS_SUCCESS";
export const GET_RECOMMENDED_PROJECTS_FAILURE = "GET_RECOMMENDED_PROJECTS_FAILURE";
export const GET_RECOMMENDED_PROJECTS_RESET = "GET_RECOMMENDED_PROJECTS_RESET";

export const SEARCH_PROJECTS_REQUEST = "SEARCH_PROJECTS_REQUEST";
export const SEARCH_PROJECTS_SUCCESS = "SEARCH_PROJECTS_SUCCESS";
export const SEARCH_PROJECTS_FAILURE = "SEARCH_PROJECTS_FAILURE";
export const SEARCH_PROJECTS_RESET = "SEARCH_PROJECTS_RESET";

export const GET_PROJECT_REQUEST = "GET_PROJECT_REQUEST";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILURE = "GET_PROJECT_FAILURE";
export const GET_PROJECT_RESET = "GET_PROJECT_RESET";

export const GET_OWN_PROJECTS_REQUEST = "GET_OWN_PROJECTS_REQUEST";
export const GET_OWN_PROJECTS_SUCCESS = "GET_OWN_PROJECTS_SUCCESS";
export const GET_OWN_PROJECTS_FAILURE = "GET_OWN_PROJECTS_FAILURE";
export const GET_OWN_PROJECTS_RESET = "GET_OWN_PROJECTS_RESET";

export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAILURE = "CREATE_PROJECT_FAILURE";
export const CREATE_PROJECT_RESET = "CREATE_PROJECT_RESET";

export const EDIT_PROJECT_REQUEST = "EDIT_PROJECT_REQUEST";
export const EDIT_PROJECT_SUCCESS = "EDIT_PROJECT_SUCCESS";
export const EDIT_PROJECT_FAILURE = "EDIT_PROJECT_FAILURE";
export const EDIT_PROJECT_RESET = "EDIT_PROJECT_RESET";

export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";
export const DELETE_PROJECT_RESET = "DELETE_PROJECT_RESET";

export const DISCARD_PROJECT_REQUEST = "DISCARD_PROJECT_REQUEST";
export const DISCARD_PROJECT_SUCCESS = "DISCARD_PROJECT_SUCCESS";
export const DISCARD_PROJECT_FAILURE = "DISCARD_PROJECT_FAILURE";
export const DISCARD_PROJECT_RESET = "DISCARD_PROJECT_RESET";

export const FINISH_PROJECT_REQUEST = "FINISH_PROJECT_REQUEST";
export const FINISH_PROJECT_SUCCESS = "FINISH_PROJECT_SUCCESS";
export const FINISH_PROJECT_FAILURE = "FINISH_PROJECT_FAILURE";
export const FINISH_PROJECT_RESET = "FINISH_PROJECT_RESET";

export const RATE_PROJECT_REQUEST = "RATE_PROJECT_REQUEST";
export const RATE_PROJECT_SUCCESS = "RATE_PROJECT_SUCCESS";
export const RATE_PROJECT_FAILURE = "RATE_PROJECT_FAILURE";
export const RATE_PROJECT_RESET = "RATE_PROJECT_RESET";

export const CREATE_BID_REQUEST = "CREATE_BID_REQUEST";
export const CREATE_BID_SUCCESS = "CREATE_BID_SUCCESS";
export const CREATE_BID_FAILURE = "CREATE_BID_FAILURE";
export const CREATE_BID_RESET = "CREATE_BID_RESET";

export const ACCEPT_BID_REQUEST = "ACCEPT_BID_REQUEST";
export const ACCEPT_BID_SUCCESS = "ACCEPT_BID_SUCCESS";
export const ACCEPT_BID_FAILURE = "ACCEPT_BID_FAILURE";
export const ACCEPT_BID_RESET = "ACCEPT_BID_RESET";

export const DISCARD_BID_REQUEST = "DISCARD_BID_REQUEST";
export const DISCARD_BID_SUCCESS = "DISCARD_BID_SUCCESS";
export const DISCARD_BID_FAILURE = "DISCARD_BID_FAILURE";
export const DISCARD_BID_RESET = "DISCARD_BID_RESET";

export const GET_TAG_REQUEST = "GET_TAG_REQUEST";
export const GET_TAG_SUCCESS = "GET_TAG_SUCCESS";
export const GET_TAG_FAILURE = "GET_TAG_FAILURE";
export const GET_TAG_RESET = "GET_TAG_RESET";

export const CREATE_ANNOTATION_REQUEST = "CREATE_ANNOTATION_REQUEST";
export const CREATE_ANNOTATION_SUCCESS = "CREATE_ANNOTATION_SUCCESS";
export const CREATE_ANNOTATION_FAILURE = "CREATE_ANNOTATION_FAILURE";
export const CREATE_ANNOTATION_RESET = "CREATE_ANNOTATION_RESET";

export const GET_ANNOTATIONS_REQUEST = "GET_ANNOTATIONS_REQUEST";
export const GET_ANNOTATIONS_SUCCESS = "GET_ANNOTATIONS_SUCCESS";
export const GET_ANNOTATIONS_FAILURE = "GET_ANNOTATIONS_FAILURE";
export const GET_ANNOTATIONS_RESET = "GET_ANNOTATIONS_RESET";