import React from "react"
import {Link} from "react-router-dom";
import "./CompanyCard.css"

class CompanyCard extends React.Component {

    renderAdmin() {
        if (this.props.isAdmin) {
            const {company} = this.props;
            return (
                <div className="right floated content">
                    <Link to={`/companies/edit/${company.id}`}
                          className="ui button primary">
                        Edit
                    </Link>
                    <Link to={`/companies/delete/${company.id}`}
                          className="ui button negative">
                        Delete
                    </Link>
                </div>
            );
        }
    }


    render() {
        const {company} = this.props;
        return (
            <div className="item" key={company.id}>
                {this.renderAdmin(company)}
                {/*<i className="large middle aligned icon camera"/>*/}
                {/*<ImageCard imageUrl={company.profileImage} description={company.name}/>*/}
                <div>
                    <Link to={`/companies/${company.id}`} className="ui image teal label">
                    <img alt="edgify"
                          src={company.profileImage}/>
                        {company.name}
                        <div className="detail">{company.about}</div>
                    </Link>

                </div>

                <div className="content">

                    {/*/!*<div className="summary">*!/*/}
                    {/*/!*</div>*!/*/}
                    {/*<Link to={`/companies/${company.id}`} className="header">*/}
                    {/*    {company.name}*/}
                    {/*</Link>*/}
                    {/*<div className="description"> {company.about}</div>*/}
                    {/*<div className="label"> website: {company.website}</div>*/}
                    {/*<div className="description"> {company.profileImage}</div>*/}
                </div>


                {/*<div className="content">*/}
                {/*    <Link to={`/companies/${company.id}`} className="header">*/}
                {/*        {company.name}*/}
                {/*    </Link>*/}
                {/*    <div className="description"> {company.about}</div>*/}
                {/*    <div className="description"> {company.website}</div>*/}
                {/*    <div className="description"> {company.profileImage}</div>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default CompanyCard;