/*eslint-disable*/
import React, {useState} from "react";
import {Link} from "react-router-dom";


// components

import NotificationDropdown from "../Dropdowns/NotificationDropdown";
import GoogleAuthHook from "./GoogleAuthHook";
import UserDropdown from "../Dropdowns/UserDropdown";
import GoogleButton from "./GoogleButton";
import {connect, useSelector} from "react-redux";

const  Navbar = (props) => {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const {loggedIn} = props.auth;

    const isSignedIn = false;
    // const {profile, isSignedIn} = useSelector(state => state.auth.qualiifly)
    // const {isGoogleSignedIn, onSignInClick, onSignOutClick} = GoogleAuthHook();


    const getWelcomeMessage = () => {
        const today = new Date()
        const curHr = today.getHours()
        let retMessage;
        if (curHr < 12) {
            retMessage = 'Good Morning'
        } else if (curHr < 18) {
            retMessage = 'Good Afternoon'
        } else {
            retMessage = 'Good Evening'
        }
        return retMessage;
    }
    const renderAuth = () => {
        console.log("renderAuth")
        if(loggedIn===null){
            return null
        }else if (loggedIn) {
            //todo need to fix
            const onSignOutClick = ()=>{ }
            const profile = {fullName:"",givenName:"Anonymous", imageURL:"https://res.cloudinary.com/qualiifly/image/upload/v1613315389/avatars/avatar-20.jpg?ade058f0-6ed6-11eb-b8c4-8f7217ac6b6d"}
            const name = isSignedIn ? profile.givenName : "Anonymous";
            const welcome = `${getWelcomeMessage()}, ${name}`;
            return (
                <React.Fragment>
                    <li className="m-2 inline-block items-center relative">
                        <NotificationDropdown/>
                    </li>
                    <li className="m-2 inline-block items-center relative">
                        <h1 className="m-2 text-sm text-white inline-flex items-center justify-center rounded-full">{welcome}</h1>
                    </li>
                    <li className="m-2 inline-block items-center relative">
                        <UserDropdown profile={profile} onSignOutClick={onSignOutClick}/>
                    </li>
                </React.Fragment>

            );
        } else {
            return (

                <React.Fragment>
                    {/*<li className="inline-block items-center relative">*/}
                    {/*    <GoogleButton isSignedIn={isGoogleSignedIn} onSignInClick={onSignInClick} onSignOutClick={onSignOutClick}/>*/}
                    {/*</li>*/}
                    {/*<li className="inline-block items-center relative">*/}
                    {/*    <Link*/}
                    {/*        to="/auth/login"*/}
                    {/*        className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"*/}
                    {/*    >*/}
                    {/*        Sign In*/}
                    {/*    </Link>*/}
                    {/*</li>*/}

                    {/*<li className="inline-block items-center relative">*/}
                    {/*    <Link*/}
                    {/*        to="/auth/register"*/}
                    {/*        className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"*/}
                    {/*    >*/}
                    {/*        Register*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                </React.Fragment>
            );
        }
    }


    return (
        <>
            <nav
                className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
                <div className="container px-4 mx-auto flex flex-wrap items-center justify-between" >
                    <div className="flex items-center">
                        <a
                            className="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                            href="https://www.linkedin.com/company/qualiifly"
                            target="_blank"
                        >
                            <i className="lg:text-gray-300 text-gray-500 fab fa-linkedin-in text-lg leading-lg "/>
                            <span className="lg:hidden inline-block ml-2">Share</span>
                        </a>
                    </div>
                    <div className="flex items-center lg:w-auto lg:static lg:block lg:justify-start">
                        <Link
                            className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase"
                            to="/"
                        >
                            Qualiifly
                        </Link>

                        <button
                            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                            type="button"
                            onClick={() => setNavbarOpen(prevState => !prevState)}
                        >
                            <i className="text-white fas fa-bars"></i>
                        </button>
                    </div>
                    <div
                        className={
                            "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
                            (navbarOpen ? " block rounded shadow-lg" : " hidden")
                        }
                        id="example-navbar-warning"
                    >
                        {/*<ul className="flex flex-col lg:flex-row list-none mr-auto">*/}
                        {/*    <li className="flex items-center">*/}
                        {/*        <Link*/}
                        {/*            className="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"*/}
                        {/*            to="/interviewer-marketplace"*/}
                        {/*        >*/}
                        {/*            <i className="lg:text-gray-300 text-gray-500 fas fa-shopping-cart text-lg leading-lg mr-2"/>{" "}*/}
                        {/*            Marketplace*/}
                        {/*        </Link>*/}
                        {/*    </li>*/}
                        {/*</ul>*/}
                        <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                            {renderAuth()}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}



const mapStateToProps = state => ({
    auth: state.auth
});


export default connect(mapStateToProps)(Navbar);
