export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";
export const GET_PROFILE_RESET = "GET_PROFILE_RESET";

export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";
export const GET_USER_PROFILE_RESET = "GET_USER_PROFILE_RESET";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const CHANGE_PASSWORD_RESET = "CHANGE_PASSWORD_RESET";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";

export const GET_PORTFOLIO_REQUEST = "GET_PORTFOLIO_REQUEST";
export const GET_PORTFOLIO_SUCCESS = "GET_PORTFOLIO_SUCCESS";
export const GET_PORTFOLIO_FAILURE = "GET_PORTFOLIO_FAILURE";
export const GET_PORTFOLIO_RESET = "GET_PORTFOLIO_RESET";

export const POST_PORTFOLIO_REQUEST = "POST_PORTFOLIO_REQUEST";
export const POST_PORTFOLIO_SUCCESS = "POST_PORTFOLIO_SUCCESS";
export const POST_PORTFOLIO_FAILURE = "POST_PORTFOLIO_FAILURE";
export const POST_PORTFOLIO_RESET = "POST_PORTFOLIO_RESET";

export const PUT_PORTFOLIO_REQUEST = "PUT_PORTFOLIO_REQUEST";
export const PUT_PORTFOLIO_SUCCESS = "PUT_PORTFOLIO_SUCCESS";
export const PUT_PORTFOLIO_FAILURE = "PUT_PORTFOLIO_FAILURE";
export const PUT_PORTFOLIO_RESET = "PUT_PORTFOLIO_RESET";

export const DELETE_PORTFOLIO_REQUEST = "DELETE_PORTFOLIO_REQUEST";
export const DELETE_PORTFOLIO_SUCCESS = "DELETE_PORTFOLIO_SUCCESS";
export const DELETE_PORTFOLIO_FAILURE = "DELETE_PORTFOLIO_FAILURE";
export const DELETE_PORTFOLIO_RESET = "DELETE_PORTFOLIO_RESET";

export const PUT_WALLET_REQUEST = "PUT_WALLET_REQUEST";
export const PUT_WALLET_SUCCESS = "PUT_WALLET_SUCCESS";
export const PUT_WALLET_FAILURE = "PUT_WALLET_FAILURE";
export const PUT_WALLET_RESET = "PUT_WALLET_RESET";

export const GET_CONVERSATIONS_REQUEST = "GET_CONVERSATIONS_REQUEST";
export const GET_CONVERSATIONS_SUCCESS = "GET_CONVERSATIONS_SUCCESS";
export const GET_CONVERSATIONS_FAILURE = "GET_CONVERSATIONS_FAILURE";
export const GET_CONVERSATIONS_RESET = "GET_CONVERSATIONS_RESET";

export const GET_CONVERSATION_REQUEST = "GET_CONVERSATION_REQUEST";
export const GET_CONVERSATION_SUCCESS = "GET_CONVERSATION_SUCCESS";
export const GET_CONVERSATION_FAILURE = "GET_CONVERSATION_FAILURE";
export const GET_CONVERSATION_RESET = "GET_CONVERSATION_RESET";

export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILURE = "SEND_MESSAGE_FAILURE";
export const SEND_MESSAGE_RESET = "SEND_MESSAGE_RESET";

export const GET_RECOMMENDED_USERS_REQUEST = "GET_RECOMMENDED_USERS_REQUEST";
export const GET_RECOMMENDED_USERS_SUCCESS = "GET_RECOMMENDED_USERS_SUCCESS";
export const GET_RECOMMENDED_USERS_FAILURE = "GET_RECOMMENDED_USERS_FAILURE";
export const GET_RECOMMENDED_USERS_RESET = "GET_RECOMMENDED_USERS_RESET";
