import _ from "lodash"
import React from "react";

import {connect} from "react-redux"
import CompanyForm from "./CompanyForm";
import allActions from "../../../actions";

class CompanyEdit extends React.Component {

    componentDidMount() {
        this.props.fetchCompany(this.props.match.params.id)
    }

    onSubmit = formValues => {
        this.props.editCompany(this.props.match.params.id,formValues)
    }

    render() {
        if (!this.props.company) {
            return (<div>Loading...</div>);
        }

        return (
            <div>
                <h3>Edit a Company</h3>
                <CompanyForm
                    initialValues={_.pick(this.props.company, 'title', 'description')}
                    onSubmit={this.onSubmit} />
            </div>
        );

    }


}

const mapStateToProps = (state, ownProps) => {

    return {company: state.companies[ownProps.match.params.id]};
};

export default connect(mapStateToProps,
    {fetchCompany: allActions.companyActions.fetchCompany, editCompany: allActions.companyActions.editCompany})(CompanyEdit);