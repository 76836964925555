import React from "react";
import {connect} from "react-redux";
import allActions from "../../../actions";
import history from "../../../history";
import ModalContainer from "../../../components/ModalContainer";
import CompanyForm from "./CompanyForm";

class CompanyCreate extends React.Component {
    onSubmit = formValues => {
        this.props.createCompany(formValues)
    }
    renderContent = ()=>{
        return (
            <CompanyForm />
        );

    }

    render() {
        console.log("create")
        return (
            <div>CompanyDelete
                <ModalContainer
                    title="Delete Company"
                    content={this.renderContent()}
                    onDismiss={() => history.push('/recruiter/dashboard')}
                />
            </div>
        );
    }
}


export default connect(null, {createCompany: allActions.companyActions.createCompany})(CompanyCreate);