import React from "react";
import {Switch, Route, Redirect, Router} from "react-router-dom";

// components
import history from "../history";

// views
import AccountSettings from "views/user/AccountSettings.js";
import Profile from "../views/user/Profile";
import EditProfile from "../views/user/EditProfile";
import Tables from "../views/admin/Tables";
import Navbar from "../components/Navbars/AuthNavbar";
import UserProfile from "../views/UserProfile/UserProfile";
import Settings from "views/admin/Settings.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import HeaderStats from "../components/Headers/HeaderStats";
import FooterAdmin from "../components/Footers/FooterAdmin";
import Sidebar from "../components/Sidebar/Sidebar";


class User extends React.Component {
    render() {
        return (
            <>
                <Sidebar />
                <Navbar transparent />
                <AdminNavbar />
                {/* Header */}
                <HeaderStats />
                <div className="relative md:ml-64 bg-gray-200">
                    <div className="px-4 md:px-10 mx-auto w-full -m-24">
                        <Switch>
                            <Route history={history} path="/user/user-profile" exact component={UserProfile}/>
                            <Route history={history} path="/user/profile" exact component={Profile}/>
                            <Route history={history} path="/user/edit-profile" exact component={EditProfile}/>
                            <Route history={history} path="/user/account-settings" exact component={AccountSettings}/>
                            <Redirect from="/user" to="/user/profile"/>
                        </Switch>
                        {/*<FooterAdmin />*/}
                    </div>
                </div>
            </>
        );
    }


}


export default User;