import history from "../history";

import {SIGN_IN, SIGN_OUT} from "./types";

import qualiiflyApi, {authHeader} from "../apis/qualiiflyApi";

const signIn = (userId, token, profile) => async dispatch => {
    let qualiiflyUser = {}

    if (token) {
        const response = await qualiiflyApi.post('/login/google', {idToken: token.id_token});
        qualiiflyUser = response.data
        localStorage.setItem('jwtToken', qualiiflyUser.token);
    } else {
        localStorage.setItem('jwtToken', null);
    }
    dispatch({type: SIGN_IN, payload: {google: {userId, token, profile}, qualiifly: qualiiflyUser}});
    if(qualiiflyUser.profile_id === null){
        history.push("/user/edit-profile")
    }
};


const signOut = (userId, token, profile) => async (dispatch, getState) => {
    // if (getState().auth.qualiifly.token) {
    //     console.log("sign out", authHeader(token) )
    //     const response = await qualiiflyApi.post('/logout', {headers: authHeader()});
    //     localStorage.removeItem('jwtToken')
    // }

    dispatch({type: SIGN_OUT});
};

// eslint-disable-next-line
export default {
    signIn,
    signOut
}