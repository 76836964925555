import axios from "axios";

// (function() {
//     const token = localStorage.getItem('jwtToken');
//     if (token) {
//         axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//     } else {
//         axios.defaults.headers.common['Authorization'] = null;
//         /*if setting null does not remove `Authorization` header then try
//           delete axios.defaults.headers.common['Authorization'];
//         */
//     }
// })();

export  function authHeader() {
    const token = localStorage.getItem('jwtToken');
    console.log("------------------token",token)
    if (token) {
        return { Authorization: 'Bearer ' + token };
    } else {
        return {};
    }
}

export default axios.create({
    baseURL: 'http://localhost:3333/api/v1'
});
