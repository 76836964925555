import React from "react";
import {Link} from "react-router-dom";

class PlusButton extends React.Component {


    render() {
        return (
        <Link
            to="/recruiter/company/new"
            className="fas fa-plus-circle px-4 py-3"
        />);
    }
}

export default PlusButton;
