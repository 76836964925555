import React, {useEffect, useState} from "react";
import {connect, useSelector} from 'react-redux'

import Footer from "components/Footers/Footer.js";
import allActions from "../../actions";
import FullPageLoading from "../../components/Loading/FullPageLoading";
import TopBackground from "../../components/Headers/TopBackground";


const Profile = (props) => {

    // const  data  = useSelector( (state) => {
    //     return {
    //         qualiifly: state.auth.qualiifly,
    //         google: state.auth.google,
    //         profile: state.profile
    //     }
    // });
    const {profile_id} = props.qualiifly

    /* use a state to hold the render */
    const [isFirstRender, setFirstRender] = useState(true)


    /* after the first render the user should be logged in (if previously was) */
    useEffect(() => {
        setFirstRender(false)
    }, [])

    useEffect(() => {
        props.fetchProfile()

    }, [profile_id]);

    const isEmpty = (obj) => {
        return !obj || Object.keys(obj).length === 0;
    };

    if (isEmpty(props.profile)) {
        return ( <FullPageLoading />);
    }else {
        const imageUrl =  props.google.profile.imageURL;
        const fullName =  `${props.qualiifly.given_name} ${props.qualiifly.family_name}`
        const location = `${props.profile.address} ${props.profile.city}, ${props.profile.country} ${props.profile.postal_code}`;
        const title = props.profile.title;
        const eduction = `${props.profile.degree} ${props.profile.field_of_study}, ${props.profile.school}`

        return (
            <>
                <main className="profile-page">
                    <TopBackground />
                    <section className="relative py-16 bg-gray-300">
                        <div className="container mx-auto px-10">
                            <div
                                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-32">
                                <div className="px-6">
                                    <div className="flex flex-wrap justify-center">
                                        <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                                            <div className="relative">
                                                <img
                                                    alt="..."
                                                    src={imageUrl}
                                                    className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                                            <div className="py-6 px-3 mt-32 sm:mt-0">
                                                <button
                                                    className="bg-blue-500 active:bg-blue-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                >
                                                    Connect
                                                </button>
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4 lg:order-1">
                                            <div className="flex justify-center py-4 lg:pt-4 pt-8">
                                                <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          22
                        </span>
                                                    <span className="text-sm text-gray-500">Friends</span>
                                                </div>
                                                <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          10
                        </span>
                                                    <span className="text-sm text-gray-500">Photos</span>
                                                </div>
                                                <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          89
                        </span>
                                                    <span className="text-sm text-gray-500">Comments</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-12">
                                        <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
                                            {fullName}
                                        </h3>
                                        <div
                                            className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold capitalize">
                                            <i className="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>{" "}
                                            {location}
                                        </div>
                                        <div className="mb-2 text-gray-700 mt-10">
                                            <i className="fas fa-briefcase mr-2 text-lg text-gray-500"></i>
                                            {title}
                                        </div>
                                        <div className="mb-2 text-gray-700">
                                            <i className="fas fa-university mr-2 text-lg text-gray-500"></i>
                                            {eduction}
                                        </div>
                                    </div>
                                    <div className="mt-10 py-10 border-t border-gray-300 text-center">
                                        <div className="flex flex-wrap justify-center">
                                            <div className="w-full lg:w-9/12 px-4">
                                                <p className="mb-4 text-lg leading-relaxed text-gray-800">
                                                    {props.profile.about_me}
                                                </p>
                                                {/*<a*/}
                                                {/*  href="#pablo"*/}
                                                {/*  className="font-normal text-blue-500"*/}
                                                {/*  onClick={(e) => e.preventDefault()}*/}
                                                {/*>*/}
                                                {/*  Show more*/}
                                                {/*</a>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer/>
            </>
        );
    }



};

const mapStateToProps = (state) => {
    return {
        qualiifly: state.auth.qualiifly,
        google: state.auth.google,
        profile: state.profile.data
    }
}

export default connect(mapStateToProps, {fetchProfile: allActions.profileActions.fetchProfile})(Profile);