import React from "react"
import PageElement from "./PageElement";

const PageDropdownList = (props) => {
    const {root, arrayOfRouts, onPopoverClicked} = props
    const renderList = (root, arrayOfRouts) => {
        return arrayOfRouts.map(rout => {
            return (
                <PageElement key={rout.displayName}
                             to={`${root}/${rout.path}`}
                             displayName={rout.displayName}
                             onPopoverClicked={onPopoverClicked}/>
            )
        });
    }
    return (
        <React.Fragment>
        <span
            className={
                "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-no-wrap bg-transparent text-gray-500"
            }
        >
          {props.section}
        </span>
            {renderList(root, arrayOfRouts)}
        </React.Fragment>
    );
}

export default PageDropdownList;