import React, { Component } from "react";

import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";
import LoginForm from "../../components/Form/LoginForm";


const dashboardRoutes = [];

class LoginPage extends Component {
    render() {
        const { classes, history, ...rest } = this.props;
        return (
            <div>
                <Navbar transparent />
                <section className="relative w-full h-full py-40 min-h-screen">
                    <div
                        className="absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full"
                        style={{
                            backgroundImage:
                                "url(" + require("assets/img/register_bg_2.png") + ")",
                        }}
                     />
                <LoginForm history={history}></LoginForm>
                    <FooterSmall absolute />
                </section>
            </div>
        );
    }
}

export default LoginPage;